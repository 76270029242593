export function exportToCsv(data, columns) {
  // Function to get nested property value
  const getNestedValue = (item, keys) => {
    let value = item;
    for (const key of keys.split(".")) {
      value = value[key];
    }
    return value?.toString();
  };

  // Get headers from columns
  const headers = columns.reduce((acc, column) => {
    if (column.children) {
      return acc.concat(column.children.map((child) => child.title));
    }
    return acc.concat(column.title);
  }, []);

  console.log(headers)

  // Convert data to CSV rows
  const csvRows = data.map((item) => {
    const row = [];
    columns.forEach((column) => {
      if (column?.dataIndex === "Student") {
      column.children.forEach((child) => {
        row.push(item[child?.dataIndex]?'"'+item[child?.dataIndex]+'"':"");
        });
    }
     else if (column.children && column?.dataIndex=="sid") {
        column.children.forEach((child) => {
         const nestedValue = item[column.dataIndex];
         row.push(
           nestedValue[child.dataIndex]
             ? '"' + nestedValue[child.dataIndex] + '"'
             : ""
         );
          });
        }
     else if (column.children) {
        column.children.forEach((child) => {
         const nestedValue = item[column.dataIndex][0];
         row.push(
           nestedValue[child.dataIndex]
             ? '"'+nestedValue[child.dataIndex]+'"'
             : ""
         );
          });
        }
      else {
        row.push(item[column.dataIndex]);
      }
    });
    return row.join(",");
  });

  // Combine headers and rows
  const csvContent = [headers.join(","), ...csvRows].join("\n");
  console.log(csvContent)

  // Create a blob and generate a download link
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.setAttribute("href", URL.createObjectURL(blob));
  link.setAttribute("download", "export.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}








// export function exportToCsv(data, columns) {
//   // Create headers
//   const headers = columns
//     .map((column) => {
//       if (column?.children) {
//         return column?.children.map((child) => child?.title).join(",");
//       }
//       return column?.title;
//     })
//     .join(",");
  
//   console.log(headers)

//   // Create rows
//   const rows = data.map((item) => {
//     const rowData = columns
//       .map((column) => {
        // if (column?.dataIndex == "Student") {
        //   if (column?.children) {
        //     return column?.children
        //       .map((child) => item[child?.dataIndex])
        //       .join(",");
        //   }
          
        // }
//         if (column?.children) {
//           return column?.children
//             .map((child) => {
//               console.log(item[column?.dataIndex][child?.dataIndex]);
//               return item[column?.dataIndex][child?.dataIndex]
//             })
//             .join(",");
//         }
//         return item[column?.dataIndex];
//       })
//       .join(",");
    
//     console.log(rowData)
    
//     return rowData;
//   });

//   // Combine headers and rows
//   const csvContent = [headers, ...rows].join("\n");
  
//   console.log(csvContent)
//   // Create a blob and generate a download link
//   // const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//   // const link = document.createElement("a");
//   // link.setAttribute("href", URL.createObjectURL(blob));
//   // link.setAttribute("download", "export.csv");
//   // document.body.appendChild(link);
//   // link.click();
//   // document.body.removeChild(link);
// }
