export    const schema = [
  {
    label: "Student Basic Details",
    value: "sid",
    fields: [
      {
        label: "Email",
        value: "studentEmail",
      },
      {
        label: "Phone Number",
        value: "studentPhone",
      },
    ],
  },
  {
    label: "Student Information",
    value: "Student",
    fields: [
      {
        label: "rollNumber",
        value: "rollNumber",
      },
      {
        label: "about",
        value: "about",
      },
    ],
  },
  {
    label: "Education Details",
    value: "education",
    fields: [
      {
        label: "Type (SECONDARY/INTERMEDIATE/...)",
        value: "program",
      },
      {
        label: "Institute Name",
        value: "instituteName",
      },
      {
        label: "Institute Location",
        value: "place",
      },
      {
        label: "Grades/Marks",
        value: "gradesorMarks",
      },
      {
        label: "Grade Points",
        value: "grades",
      },
      {
        label: "Marks",
        value: "marks",
      },
      {
        label: "Marks Memo",
        value: "completion",
      },
      {
        label: "TC",
        value: "tc",
      },
      {
        label: "Start Date",
        value: "startDate",
      },
      {
        label: "End Date",
        value: "endDate",
      },
      {
        label: "Board",
        value: "board",
      },
      {
        label: "Field Of Study",
        value: "fieldOfStudy",
      },
    ],
  },
  {
    label: "Skills",
    value: "Skills",
    fields: [
      {
        label: "skillName",
        value: "skillName",
      },
    ],
  },
  {
    label: "SkillRating",
    value: "SkillRating",
    fields: [
      {
        label: "Skill Rating",
        value: "rating",
      },
      {
        label: "No Of Years Of Skill Experience",
        value: "years",
      },
    ],
  },
  {
    label: "Volunteering",
    value: "volunteering",
    fields: [
      {
        label: "Name Of the Organization",
        value: "organizationName",
      },
      {
        label: "Information About the Volunteering Role",
        value: "description",
      },
      {
        label: "Name Of the Event",
        value: "eventName",
      },
      {
        label: "National/International/State",
        value: "level",
      },
      // ... other fields from Volunteering
    ],
  },
  // ... other tables and their first five fields from your schema
];