import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
	HomeOutlined,
	FormOutlined,
	UserOutlined,
	LogoutOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import './Main.css';
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../UserContext";

const { Header, Sider } = Layout;
const Main = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { handleLogout } = useContext(UserContext);
	const [openKeys, setOpenKeys] = useState([]);

	const defaultSelectedKeys = () => {
		const pathname = location.pathname;

		const menuItems = [
			"/hod-helper/home",
			"/hod-helper/attendance",
			"/hod-helper/timetable",
		];

		const index = menuItems.findIndex((item) => pathname.includes(item));
		return index !== -1 ? [String(index + 1)] : ["1"];
	};

	const handleMenuItemClick = ({ keyPath }) => {
		const isProfileSubmenu = keyPath && keyPath[1] === "1";
		setOpenKeys(isProfileSubmenu ? openKeys : []);
	};

	const Logout = () => {
		handleLogout();
		navigate("/");
	};

	return (
		<Layout>
			<Sider
				className="side"
				breakpoint="lg"
				collapsedWidth="0"
				style={{
					height: "100vh",
					position: "fixed",
					left: "0",
					top: "80px",
					bottom: 0,
					zIndex: 1,
					background: "var(--color-primary)",
					color: "black",
				}}>
				<div className="demo-logo-vertical"></div>
				<Menu
					mode="inline"
					theme="light"
					defaultSelectedKeys={defaultSelectedKeys()}
					openKeys={openKeys}
					onClick={handleMenuItemClick}
					className="menu">
					<Menu.Item key="1" icon={<UserOutlined />}>
						<Link to="/profile">Profile</Link>
					</Menu.Item>
					<Menu.Item key="2" icon={<HomeOutlined />}>
						<Link to="/hod-helper/attendance">Attendance</Link>
					</Menu.Item>
					<Menu.Item key="3" icon={<FormOutlined />}>
						<Tooltip title="Classes and Notes">
							<Link to="/hod-helper/timetable">Time Table</Link>
						</Tooltip>
					</Menu.Item>
				</Menu>
			</Sider>
			<Layout>
				<Header className="head">
					<div className="title">Profile Management System</div>
					<div
						style={{ display: "flex", alignItems: "center" }}
						className="head-icons">
						<Button type="text" style={{ fontSize: "20px" }} onClick={Logout}>
							<LogoutOutlined />
						</Button>
					</div>
				</Header>
				<div className="content">{children}</div>
			</Layout>
		</Layout>
	);
};
export default Main;
