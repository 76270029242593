import React from 'react'
import Main from '../Layouts/Main'
const ALUMINI_ADMIN_HOME = () => {
  return (
    <Main>
        <div>ALUMINI_ADMIN_HOME</div>
    </Main>
  )
}

export default ALUMINI_ADMIN_HOME