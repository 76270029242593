export    const schema = [
  {
    label: "Student Basic Details",
    value: "sid",
    fields: [
      {
        label: "Email",
        value: "studentEmail",
      },
      {
        label: "Phone Number",
        value: "studentPhone",
      },
      {
        label: "Gender",
        value: "gender",
      },
      {
        label: "First Name",
        value: "firstName",
      },
      {
        label: "Last Name",
        value: "lastName",
      },
      {
        label: "Full Name",
        value: "fullName",
      },
      {
        label: "Department Name",
        value: "department",
      },
      {
        label: "College",
        value: "College",
      },
      {
        label: "University",
        value: "university",
      },
      {
        label: "Address",
        value: "permanentAddress",
      },
      {
        label: "Nationality",
        value: "nationality",
      },
      {
        label: "State",
        value: "state",
      },
      {
        label: "District",
        value: "district",
      },
      {
        label: "City",
        value: "city",
      },
      {
        label: "Mandal",
        value: "mandal",
      },
      {
        label: "Village",
        value: "village",
      },
      {
        label: "Religion",
        value: "religion",
      },
      {
        label: "Reservation Category",
        value: "category",
      },
      {
        label: "Community",
        value: "community",
      },
      {
        label: "Caste Name",
        value: "casteName",
      },
      {
        label: "Program Name (Ex: Btech) ",
        value: "program",
      },
      {
        label: "Program Code (Ex: Bachelor Of Tech) ",
        value: "programCode",
      },
      {
        label: "Aadhaar Number",
        value: "studentAadhaar",
      },
    ],
  },
  {
    label: "Student Information",
    value: "Student",
    fields: [
      {
        label: "Roll Number",
        value: "rollNumber",
      },
      {
        label: "About",
        value: "about",
      },
      {
        label: "Resume Link",
        value: "resumeUrl",
      },
    ],
  },
  {
    label: "Education Details",
    value: "education",
    fields: [
      {
        label: "Type (SECONDARY/INTERMEDIATE/...)",
        value: "program",
      },
      {
        label: "Institute Name",
        value: "instituteName",
      },
      {
        label: "Institute Location",
        value: "place",
      },
      {
        label: "Grades/Marks",
        value: "gradesorMarks",
      },
      {
        label: "Grade Points",
        value: "grades",
      },
      {
        label: "Marks",
        value: "marks",
      },
      {
        label: "Marks Memo",
        value: "completion",
      },
      {
        label: "TC",
        value: "tc",
      },
      {
        label: "Start Date",
        value: "startDate",
      },
      {
        label: "End Date",
        value: "endDate",
      },
      {
        label: "Board",
        value: "board",
      },
      {
        label: "Field Of Study",
        value: "fieldOfStudy",
      },
    ],
  },
];