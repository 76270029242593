import React from 'react'
import Main from '../Layouts/Main'
const EXAM_ADMIN_HOME = () => {
  return (
    <Main>
        <div>EXAM_ADMIN_HOME</div>
    </Main>
  )
}

export default EXAM_ADMIN_HOME;