import React, { useContext, useEffect, useState } from "react";
import Main from "../Layouts/Main";
import { UserContext } from "../../UserContext";
import { Button, Input, Modal, Progress, Avatar,Image, message } from "antd";
import { Upload, List} from "antd";
import "./Documents.css";
import axios from "axios";
import { EyeOutlined ,UserOutlined} from "@ant-design/icons";

const Documents = () => {
  const { hodapiurl, token } = useContext(UserContext);
  const [progress, setProgress] = useState(0);

const [pp, setpp] = useState(
  "http://localhost/pp/csehod@gmail.com_6593c3f586543a0ffa8bd576/1704710515744_6593c3f586543a0ffa8bd576_pexels-photo-417074.jpeg"
);
const [pc, setpc] = useState("")

  const handlePreview = async (url) => {
    console.log(url);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "BEARER " + token,
      }
    })
    const data = await response.blob();
    console.log(data)
    const datapp = await getBase64(data);
    console.log(datapp)
    return datapp
  };
  

  const getBase64 = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };


  const fetchFiles = async () => {
    try {
      const response = await axios.get(
        "http://localhost:3030/upload/certificates/WORKEXPERIENCE/124325463574",
        {
          headers: {
            Authorization: "BEARER " + token,
          },
        }
      ); // Replace with your backend endpoint
      console.log(response.data)
      // setFileList(response.data.files);
    } catch (error) {
      console.log(error)
      console.error("Error fetching files:", error);
    }
  };



  const handleChange = async (info) => {
    console.log("inside")
    if (info.file.status === 'uploading') {
      setProgress(info.file.percent);
    }
    if (info.file.status === 'done') {
      setProgress(100);
      console.log(info)
      // setpp(info.file.originFileObj); 
      message.success(`${info.file.name} uploaded successfully`);
    }
    if (info.file.status === 'error') {
      setProgress(0);
      message.error(`${info.file.name} upload failed`);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    console.log(pp)
    formData.append('prevUrl',pc);
    formData.append('type',"STUDENT");
    formData.append("id", "1dc1f1e2-1b92-452f-88bd-b998871abd02");
    formData.append('pp', file);

    try {
      const response = await axios.post('http://localhost:3030/upload/pp', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'BEARER ' + token,
        },
      });
      console.log(response.data)
      setpc(response?.data?.uri);
      const datapp = await handlePreview("http://localhost:3030/" + response?.data?.uri)
      console.log("http://localhost:3030/" + response.data.uri);
      setpp(datapp);
      onSuccess(response.data, file);
    } catch (error) {
      onError(error);
    }
  };



  return (
    <Main>
      <div style={{ margin: "5rem" }}>
        <div>
          <Upload
            customRequest={customRequest}
            onChange={handleChange}
            showUploadList={false}>
            <Avatar
              size={150}
              icon={<UserOutlined />}
              src={pp}
            />
          </Upload>
          <Progress style={{display:"none"}} size="small" type='line' steps={10} percent={progress} />
          {pp && <h3>{pc}</h3>}
        </div>
        {/* <div>
          <Upload
            customRequest={customRequest}
            onChange={handleChange}
            fileList={fileList}
            showUploadList={false}>
            <Avatar
              size={150}
              icon={<UserOutlined />}
              src={
                fileList.length > 0
                  ? URL.createObjectURL(fileList[0].originFileObj)
                  : null
              }
            />
          </Upload>
          <Progress type='circle' percent={progress} />

          <Upload
            customRequest={customRequest}
            fileList={fileList}
            onChange={handleChange}
            onPreview={handlePreview}
            listType='picture-card'>
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
            <img alt='Preview' style={{ width: "100%" }} src={previewImage} />
          </Modal>
          {fileList.map((file) => (
            <div key={file.uid}>
              <Progress type='circle' percent={file.percent} />
            </div>
          ))}
        </div> */}

        {/* <Upload multiple showUploadList={false} customRequest={handleUpload}>
          <Button type='primary'>Upload Files</Button>
        </Upload> */}
        {/* <Upload showUploadList={true} customRequest={handlePPUpload}> */}
        {/* <Upload
          customRequest={customRequest}
          onChange={handleChange}
          showUploadList={{
            showPreviewIcon: true,
            showDownloadIcon: false,
            showRemoveIcon: false,
            progress,
          }}>
          <button>Upload Profile Pic</button>
        </Upload> */}
        {/* <Progress type="line" percent={progress} /> */}

        {/* <Image width={200} src={pp} /> */}

        {/* <List
          dataSource={fileList}
          renderItem={(file) => (
            <List.Item
              actions={[
                <Button onClick={() => handleDelete(file)}>Delete</Button>,
                <Button onClick={() => setFileToUpdate(file)}>Update</Button>,
              ]}>
              <a href={"http://localhost:3030/" + file.path}>{file.name}</a>
            </List.Item>
          )}
        /> */}

        {/* {fileToUpdate && (
          <div>
            <Input
              placeholder='Enter new file name'
              value={updateFileName}
              onChange={(e) => setUpdateFileName(e.target.value)}
            />
            <Button onClick={handleUpdate}>Update</Button>
          </div>
        )} */}
      </div>
    </Main>
  );
};

export default Documents;
