import { Navigate,Outlet } from "react-router-dom";
import { useAuth } from "./useAuth";

export const AuthRoute = () => {
  const { token } = useAuth();
  if (!token || token=="undefined" || token=="null") {
    console.log("Not logged in ")
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};
