import React from 'react'
import { Layout, Flex } from "antd";
import supportTeam  from "../../assets/support-team.svg"
import { LeftCircleOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';




const { Content } = Layout;



const Forgot = () => {
  return (
    <Content>
      <Flex vertical align='center' style={{ padding: "20px" }}>
        <Link to="/login" style={{marginRight: "auto"}}>
        <LeftCircleOutlined style={{ fontSize: "40px" }} />
        </Link>
        <h1 style={{ textAlign: "center" }}>Please Contact Support Team</h1>
        <img
          style={{ width: "80%", maxWidth: "500px", borderRadius: "2rem" }}
          src={supportTeam}
          alt='support-team'
        />
      </Flex>
    </Content>
  );
}

export default Forgot;