import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../useAuth";
import { Button,Modal } from "antd";
import Loader from "../../../../Loader/Loader";

const DownloadCerts = ({ columns, record, type }) => {
  const [loading, setLoading] = useState(false);
  const { certsapiurl, token, success, error } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);


  const generateFilesToDownload = (columns, record) => {
    const filesToDownload = [];
    console.log(columns, record, type);

    columns &&
      columns.forEach((item) => {
        if (item?.dataIndex == type) {
          item?.children?.map((it) => {
            if (
              [
                "url",
                "tc",
                "completion",
                "completionUrl",
                "certificateUrl",
                "offerLetter",
                "hallTicketUrl",
                "resultUrl",
              ].includes(it?.dataIndex)
            ) {
              const u = record.map((dataitem) => {
                if (type == "education") {
                  return dataitem[type][0][it?.dataIndex];
                } else {
                }
              });
              console.log(u);
              filesToDownload.push({
                folder: it?.dataIndex,
                urls: u,
              });
            }
          });
        }
      });
    
   
      return filesToDownload;
  };

  const handleDownload = async () => {
    try {
      const filesToDownload = generateFilesToDownload(columns, record);
       if (filesToDownload.length == 0) {
         error("No Files To Download");
         return;
    }
      setLoading(true);

      const response = await fetch(`${certsapiurl}/upload/download`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "BEARER " + token,
        },
        body: JSON.stringify({ files: filesToDownload }),
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.zip");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      success("Downloading files successful");
      setLoading(false);
    } catch (error) {
      error("Error downloading files");
      // console.error("Error downloading files:", error);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <div style={{ marginBottom: "1rem" }}>
      <Modal
        open={isModalOpen}
        onOk={() => {
          handleDownload();
          setIsModalOpen(false);
        }}
        onCancel={() => setIsModalOpen(false)}>
        <h3>Are you sure to download the files</h3>
      </Modal>

      <Button
        style={{ marginLeft: "auto" }}
        type='primary'
        onClick={()=>setIsModalOpen(true)}>
        Download Documents
      </Button>
    </div>
  );
};

export default DownloadCerts;
