import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
	HomeOutlined,
	FormOutlined,
	DollarOutlined,
	MedicineBoxOutlined,
	AuditOutlined,
	SolutionOutlined,
	UserOutlined,
	LogoutOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import './Main.css';
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { Table, Input, Modal } from "antd";
import logo from "../../../assets/jntugv.png"

const { Header, Footer, Sider } = Layout;
const Main = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { handleLogout,success } = useContext(UserContext);
  const [openKeys, setOpenKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  

	const defaultSelectedKeys = () => {
		const pathname = location.pathname;

		const menuItems = [
			"/home",
			"/studentcenter",
			"/data",
			"/requests",
			"/attendance",
			"/classes",
			"/events",
			"/exams",
			"/documents",
			"/grievance",
		];

		const index = menuItems.findIndex((item) => pathname.includes(item));
		return index !== -1 ? [String(index + 1)] : ["2"];
	};

	const handleMenuItemClick = ({ keyPath }) => {
		const isProfileSubmenu = keyPath && keyPath[1] === "1";
		setOpenKeys(isProfileSubmenu ? openKeys : []);
	};

	const Logout = () => {
    handleLogout();
    success("logout successful")
		navigate("/");
	};

	return (
    <Layout>
      <Sider
        className='side'
        breakpoint='lg'
        collapsedWidth='0'
        style={{
          height: "calc(100vh-80px)",
          overflowY: "scroll",
          position: "fixed",
          left: "0",
          top: "80px",
          bottom: 0,
          zIndex: 1,
          background: "var(--color-primary)",
          color: "black",
        }}>
        <div className='demo-logo-vertical'>
          <img
            style={{ height: "7rem", marginInline: "auto" }}
            src={logo}
            alt='avatar'
          />
        </div>
        <Menu
          // mode='inline'
          theme='light'
          defaultSelectedKeys={defaultSelectedKeys()}
          openKeys={openKeys}
          onClick={handleMenuItemClick}
          className='menu'>
          <Menu.Item key='1' icon={<UserOutlined />}>
            <Link to='/hod/home'>Profile</Link>
          </Menu.Item>
          <Menu.Item key='2' icon={<AuditOutlined />}>
            <Link to='/hod/studentcenter'>Student Center</Link>
          </Menu.Item>
          <Menu.Item key='3' icon={<AuditOutlined />}>
            <Link to='/hod/data'>Data</Link>
          </Menu.Item>
          <Menu.Item key='4' icon={<AuditOutlined />}>
            <Link to='/hod/requests'>Requests</Link>
          </Menu.Item>
          <Menu.Item key='5' icon={<HomeOutlined />}>
            <Link to='/hod/attendance'>Attendance</Link>
          </Menu.Item>
          <Menu.Item key='6' icon={<FormOutlined />}>
            <Tooltip title='Classes and TimeTable'>
              <Link to='/hod/classes'>Classes & TimeTable</Link>
            </Tooltip>
          </Menu.Item>
          <Menu.Item key='7' icon={<SolutionOutlined />}>
            <Tooltip title='Events and clubs'>
              <Link to='/hod/events'>Events & clubs</Link>
            </Tooltip>
          </Menu.Item>
          <Menu.Item key='8' icon={<DollarOutlined />}>
            <Link to='/hod/exams'>Exams</Link>
          </Menu.Item>
          <Menu.Item key='9' icon={<MedicineBoxOutlined />}>
            <Link to='/hod/documents'>Documents</Link>
          </Menu.Item>
          <Menu.Item key='10' icon={<AuditOutlined />}>
            <Link to='/hod/grievance'>Grievance</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className='head'>
          <div className='title'>
            <span>JNTUGV</span>
            HOD Portal
          </div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className='head-icons'>
            <Button
              danger
              type='text'
              style={{ fontSize: "20px" }}
              onClick={() => setIsModalOpen(true)}>
              <LogoutOutlined />
            </Button>
          </div>
        </Header>
        <Modal
          open={isModalOpen}
          onOk={() => {
            Logout();
            setIsModalOpen(false);
          }}
          onCancel={() => setIsModalOpen(false)}>
          <h3>Are you sure to logout?</h3>
        </Modal>
        <div className='content'>
          {children}
        </div>
          <Footer
            style={{
            textAlign: "center",
              fontWeight:900
            }}>
            Student Information System JNTU GV ©2024 Eddrix
          </Footer>
      </Layout>
    </Layout>
  );
};
export default Main;
