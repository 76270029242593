import React, { useState,useEffect } from "react";
import Main from "../Layouts/Main";
import { Button, Input, Table, Divider } from "antd";
import { useAuth } from "../../useAuth";
import Papa from "papaparse";
import { InboxOutlined } from "@ant-design/icons";
import Loader from "../../Loader/Loader";


const StudentCenterInfo = () => {
  const { success, error ,hodapiurl,token} = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

      const [
        FilteredSearchStudentInformation,
        setFilteredSearchStudentInformation,
  ] = useState([]);
  


  useEffect(() => {
    fetchDetails();
  }, [])
  


  const fetchDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${hodapiurl}/api/hod/student/list`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: "BEARER " + token,
          },
        }
      );
      const data = await response.json();
      console.log(data);

      if (data) {
        const transformedData = data.map((item, index) => {
          return {
            key: index,
            ...item,
            ...item["sid"],
              departmentName: item?.sid?.department?.departmentName,
              program: item?.sid?.program?.programCode,
              admittedUnder: item?.sid?.admittedUnder?.examName,
          };
        });

        setData(transformedData);
        setFilteredSearchStudentInformation(transformedData);
      }

      // success("StudentInformation Fetched Successfully");
      setLoading(false);
    } catch (err) {
      error(`Error fetching Results`);
      setLoading(false);
    }
  };


  const tableColumns = [
    ["Roll Number", "rollNumber"],
    ["Year", "year"],
    ["First Name", "firstName"],
    ["Last Name", "lastName"],
    ["Gender", "gender"],
    ["Student Email", "studentEmail"],
    ["Student Phone", "studentPhone"],
    ["Department Name", "departmentName"],
    ["Admitted Under", "admittedUnder"],
    ["Program", "program"],
  ].map((item) => {
    return {
      title: item[0],
      dataIndex: item[1],
      key: item[1],
    };
  });


    useEffect(() => {
      const filteredResults = data?.filter((result) => {
        const nameMatch = result?.sid?.studentEmail
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase());
        return nameMatch;
      });
      console.log(filteredResults);
      if (!searchTerm) {
        setFilteredSearchStudentInformation(data);
      } else {
        setFilteredSearchStudentInformation(filteredResults);
      }
    }, [searchTerm, data]);

    if (loading) {
      return <Loader></Loader>;
    }
  

  return (
    <>
      <Divider />

      <div className='section-title'>Students Information</div>
      <Input
        className='request-search-input'
        type='text'
        placeholder='Search by student email'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {FilteredSearchStudentInformation.length > 0 && (
        <>
          <Table
            dataSource={FilteredSearchStudentInformation}
            columns={tableColumns}
          />
        </>
      )}
    </>
  );
};

export default StudentCenterInfo;
