import React from 'react'
import { Tabs,Divider } from 'antd';
import Main from '../Layouts/Main';
import StudentCenter from './StudentCenter';
import StudentCenterInfo from './StudentCenterInfo';
import StudentRegister from './StudentRegister';


const onChange = (key) => {
  console.log(key);
};


const items = [
  {
    key: "1",
    label: "Students Info",
    children: <StudentCenterInfo />,
  },
  {
    key: "2",
    label: "Student Invites",
    children: <StudentCenter />,
  },
  {
    key: "3",
    label: "Student register",
    children: <StudentRegister />,
  },
];


const ScMainData = () => {
    return (
      <Main>
        <div>
          <Tabs defaultActiveKey='1' items={items} onChange={onChange} />
        </div>
      </Main>
    );
}

export default ScMainData