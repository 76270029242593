import React, { useState, useEffect } from "react";
import { Table, Input, Button, Select } from "antd";
import Main from "../Layouts/Main";

const mockData = [
	{
		rollNumber: "001",
		name: "John Doe",
		email: "john.doe@example.com",
		year: "1st year",
		semester: "1st sem",
	},
	{
		rollNumber: "002",
		name: "Jane Doe",
		email: "jane.doe@example.com",
		year: "2nd year",
		semester: "2nd sem",
	},
	{
		rollNumber: "003",
		name: "Bob Smith",
		email: "bob.smith@example.com",
		year: "3rd year",
		semester: "1st sem",
	},
	{
		rollNumber: "004",
		name: "Alice Johnson",
		email: "alice.johnson@example.com",
		year: "4th year",
		semester: "2nd sem",
	},
	{
		rollNumber: "005",
		name: "Charlie Brown",
		email: "charlie.brown@example.com",
		year: "1st year",
		semester: "2nd sem",
	},
];

const subjects = ["Mathematics", "English", "Physics", "Chemistry", "Biology"];

const initialSubjectInfo = subjects.reduce((acc, subject) => {
	acc[subject] = { name: subject, totalClasses: "" };
	return acc;
}, {});

const Attendance = () => {
	const [attendanceData, setAttendanceData] = useState([]);
	const [subjectInfo, setSubjectInfo] = useState(initialSubjectInfo);
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedSemester, setSelectedSemester] = useState(null);

	const fetchStudentData = () => {
		setTimeout(() => {
			setAttendanceData(
				mockData
					.filter(
						(student) =>
							student.year === selectedYear &&
							student.semester === selectedSemester
					)
					.map((student) => ({ ...student, attendance: {} }))
			);
		}, 500);
	};

	useEffect(() => {
		if (selectedYear && selectedSemester) {
			fetchStudentData();
		}
	}, [selectedYear, selectedSemester]);

	const handleTotalClassesChange = (e, subjectKey) => {
		setSubjectInfo({
			...subjectInfo,
			[subjectKey]: {
				...subjectInfo[subjectKey],
				totalClasses: e.target.value,
			},
		});
	};


	const handleAttendanceChange = (e, key, subjectKey) => {
		const newData = [...attendanceData];
		const index = newData.findIndex((item) => key === item.rollNumber);
		newData[index].attendance[subjectKey] = e.target.value;
		setAttendanceData(newData);
	};

	const handleFinalSubmit = () => {
		const finalData = attendanceData.map(
			({ rollNumber, name, email, attendance }) => ({
				rollNumber,
				name,
				email,
				attendance: Object.keys(attendance).reduce((acc, subjectKey) => {
					acc[subjectKey] = {
						...attendance[subjectKey],
						totalClasses: subjectInfo[subjectKey].totalClasses,
					};
					return acc;
				}, {}),
			})
		);
		console.log("Final Attendance Data:", finalData);
	};

	const columns = [
		{
			title: "Roll Number",
			dataIndex: "rollNumber",
			key: "rollNumber",
			render: (text) => <span>{text}</span>,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text) => <span>{text}</span>,
		},
		...Object.keys(subjectInfo).map((subjectKey) => ({
			title: (
				<div>
					<span>{subjectInfo[subjectKey].name}</span>
					<br />
					<Input
						placeholder="Total Classes"
						value={subjectInfo[subjectKey].totalClasses}
						onChange={(e) => handleTotalClassesChange(e, subjectKey)}
						style={{ marginTop: "8px", width: "60%" }}
					/>
				</div>
			),
			dataIndex: `attendance.${subjectKey}`,
			key: subjectKey,
			render: (text, record) => (
				<Input
					value={text}
					onChange={(e) =>
						handleAttendanceChange(e, record.rollNumber, subjectKey)
					}
				/>
			),
		})),
	];

	const years = ["1st year", "2nd year", "3rd year", "4th year"];
	const semesters = ["1st sem", "2nd sem"];

	return (
		<Main>
			<Select
				placeholder="Select Year"
				onChange={(value) => setSelectedYear(value)}
				style={{ width: 120, marginRight: 8 }}>
				{years.map((year) => (
					<Select.Option key={year} value={year}>
						{year}
					</Select.Option>
				))}
			</Select>
			<Select
				placeholder="Select Semester"
				onChange={(value) => setSelectedSemester(value)}
				style={{ width: 120, marginRight: 8 }}>
				{semesters.map((semester) => (
					<Select.Option key={semester} value={semester}>
						{semester}
					</Select.Option>
				))}
			</Select>
			<Table dataSource={attendanceData} columns={columns} pagination={false} />
			<Button
				onClick={handleFinalSubmit}
				type="primary"
				style={{ marginBottom: 16 }}>
				Final Submit
			</Button>
		</Main>
	);
};	

export default Attendance;
