import { Table, Button, Input, Divider } from "antd";
import "./MyTable.css";
import { useReactToPrint } from "react-to-print";
import React, { useRef, useState, useEffect } from "react";

import { FilePdfOutlined } from "@ant-design/icons";
import { exportToCsv } from "./DataComponents/utlis/ExportToCsv";
import logo from "../../../assets/jntugv.png";


const MyTable = ({ dataSource, columns, title }) => {
  const [Title, setTitle] = useState("");
  const [Desc, setDesc] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setTitle(title);
  }, [title]);

    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }

  const renderColumn = (record, columnKey, columnData) => {
    // console.log(record, columnKey, columnData);
    if (record && Array.isArray(record[columnKey])) {
      if (record[columnKey]) {
        return (
          <Table
            className='table-in-col'
            dataSource={record[columnKey]}
            scroll={{ x: "min-content" }}
            bordered
            columns={Object.values(columnData).map((item) => {
              return {
                ...item,
                render: (text, record) => {
                  // console.log(item);
                  if (["startDate", "endDate", "date"].includes(item?.dataIndex)) {
                    return (
                      record &&
                      record[item?.dataIndex] &&
                      formatDate(record[item?.dataIndex])
                    );
                  }
                  return <p>{record[item?.dataIndex]}</p>;
                },
              };
            })}
            pagination={false}
            // showHeader={false}
          />
        );
      }
    }  else if (["startDate", "endDate", "date"].includes(columnKey)) {
        // console.log(columnKey);
        return record && record[columnKey] && formatDate(record[columnKey]);
      } else {
        return record && record[columnKey];
      }
    
  };

  const tableColumns = Object.keys(columns).map((table) => {
    if (table === "Student") {
      return {
        title: "Basic Details",
        dataIndex: table,
        key: table,
        children: Object.values(columns[table]).map((item) => {
          return {
            ...item,
            render: (text, record) =>
              renderColumn(record, item?.dataIndex, columns[table]),
          };
        }),
      };
    }
    if (table === "sid") {
      return {
        title: "Basic Details",
        dataIndex: table,
        key: table,
        children: Object.values(columns[table]).map((item) => {
          return {
            ...item,
            render: (text, record) =>
              renderColumn(record[table], item?.dataIndex, columns[table]),
          };
        }),
      };
    }
    return {
      title: table,
      dataIndex: table,
      key: table,
      render: (text, record) => renderColumn(record, table, columns[table]),
    };
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      {/* <Button
        style={{ marginRight: "1rem" }}
        onClick={() => setIsModalOpen(true)}>
        Export to CSV
      </Button> */}

      {/* <Modal
        open={isModalOpen}
        onOk={() => {
          exportToCsv(dataSource, tableColumns);
          setIsModalOpen(false);
        }}
        onCancel={() => setIsModalOpen(false)}>
        <h3>Do you want to download the table as CSV</h3>
      </Modal> */}

      <Button
        onClick={handlePrint}
        type='primary'
        style={{ marginBottom: "1rem" }}
        danger>
        <FilePdfOutlined /> Export to PDF{" "}
      </Button>
      <Divider />
      <strong>Set title for the table</strong>

      <Input
        placeholder={`Change title for the table`}
        type='text'
        onChange={(e) => setTitle(e?.target?.value)}
        value={Title}
      />
      <Divider />
      <strong>Set description for the table</strong>

      <Input
        placeholder={`Change Description for the table`}
        type='text'
        onChange={(e) => setDesc(e?.target?.value)}
        value={Desc}
      />
      <Divider />

      <div ref={componentRef} className='printing-div'>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <img
            style={{ height: "7rem", marginInline: "auto" }}
            src={logo}
            alt='avatar'
          />
          <div style={{ textAlign: "center" }}>
            <strong>JNTUGV College Of Engineering Vizianagaram</strong>
            <br />
            <strong>Dwarapudi, Vizianagaram, Andhra Pradesh - 535003</strong>
          </div>
        </div>
        <hr />
        <div
          style={{
            textAlign: "center",
            fontWeight: "600",
            marginBlock: "2rem",
          }}>
          {Title}
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: "300",
            fontSize: "10px",
            marginBlock: "1rem",
            paddingInline: "4rem",
          }}>
          {Desc}
        </div>{" "}
        <Table
          dataSource={dataSource}
          // scroll={{ x: true }}
          scroll={{ x: "calc(80vw - 500px)" }}
          bordered
          className='data-dataTable'
          columns={[
            {
              title: "SL-ID",
              dataIndex: "id",
              key: "id",
              render: (id, record, index) => {
                ++index;
                return index;
              },
            },
          ].concat(tableColumns)}
          pagination={false}
          tableLayout='auto'
        />
      </div>
    </>
  );
};

export default MyTable;
