import React, { useEffect, useState } from "react";
import { Button, Input, DatePicker } from "antd";
import "./StudentInfo.css";
import { useAuth } from "../../../useAuth";
import { Select,Switch, Checkbox, Collapse, Divider } from "antd";
import { schema } from "../schemas/Experience";
import MyTable from "./utlis/CustomTable";

import Mytab from "../MyTable";

import { Tooltip } from "antd";
import { expInfo } from "../schemas/batch";
import Loader from "../../../Loader/Loader";
const { RangePicker } = DatePicker;

const Experience = ({ title, batch }) => {
  const [data, setData] = useState([]);
  const [topOne, settopOne] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { hodapiurl, token, success, error } = useAuth();
  const [Title, setTitle] = useState("");
  const [
    FilteredSearchStudentInformation,
    setFilteredSearchStudentInformation,
  ] = useState([]);

  const [AddMoreFields, setAddMoreFields] = useState("");
  const [selectedBatch, setSelectedBatch] = useState("");
  const [selectedTables, setSelectedTables] = useState([]);
  const [columns, setColumns] = useState({});
  const [Options, setOptions] = useState({});

  const [selectedExp, setSelectedExp] = useState("");

  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);

  const checkAll = schema.length === selectedTables.length;
  const indeterminate =
    selectedTables.length > 0 && selectedTables.length < schema.length;

  const defaultop = {
    Student: {
      rollNumber: true,
    },
    sid: {
      fullName: true,
      yearOfAdmission: true,
    },
    experience: {
      et:true,
      mode: true,
      company: true,
      role: true,
      startDate: true,
      endDate: true,
      completionUrl: true,
      offerLetter: true,
    },
  };
  
  useEffect(() => {
    setOptions(defaultop);
    setSelectedTables(["sid", "Student", "experience"]);
  }, []);

  useEffect(() => {
    setTitle(title);
    setSelectedBatch(batch);
    console.log(Title, batch);
  }, [title, batch]);

  useEffect(() => {
    console.log(value);
    console.log(dates);
  }, [dates, value]);

  const handleFilter = async (sendOptions) => {
    // Implement logic to apply filters on API call
    // e.g., call the backend with dates[0] and dates[1]

    // Call the fetchDetails function with dates[0] and dates[1]
    let filters = {};

    if (dates && dates[0] && dates[1]) {
      filters = {
        ...filters,
        AND: [
          { startDate: { gte: dates[0].toISOString() } },
          { startDate: { lte: dates[1].toISOString() } },
        ],
      };
    }

    if (selectedExp) {
      filters = {
        ...filters,
        et: selectedExp,
      };
    }

    console.log(filters);
    await fetchDetails(sendOptions, filters);
  };

  const fetchDetails = async (sendOptions, filters) => {
    setLoading(true);
    console.log("Batch: ", selectedBatch);
    console.log(Options);
    try {
      const response = await fetch(
        `${hodapiurl}/api/hod/student/list/condition/e`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
            Authorization: "BEARER " + token,
          },
          body: JSON.stringify({
            options: sendOptions,
            year: selectedBatch,
            type: "experience",
            filters,
          }),
        }
      );
      const data = await response.json();
      console.log(data);

      if (data) {
        const transformedData = data.map((item, index) => {
          return {
            key: index,
            ...item,
            sid: {
              ...item["sid"],
              College: item?.sid?.College?.collegeName,
              university: item?.sid?.university?.universityName,
              department: item?.sid?.department?.departmentName,
              programCode: item?.sid?.program?.programCode,
              program: item?.sid?.program?.programName,
              community: item?.sid?.community?.communityName,
              category: item?.sid?.category?.categoryName,
              admittedUnder: item?.sid?.admittedUnder?.examName,
            },
          };
        });

        setData(transformedData);
        setFilteredSearchStudentInformation(transformedData);
      }

      success("StudentInformation Fetched Successfully");
      setLoading(false);
    } catch (err) {
      error(`Error fetching Results`);
      setLoading(false);
    }
  };

  const onCheckAllChange = (e) => {
    const sc = schema.map((item) => {
      return item.value;
    });
    setSelectedTables(e.target.checked ? sc : []);
  };

  const handleFieldChange = (e, table, i) => {
    const s = {};
    const o = {};
    e.forEach((item) => {
      const checkItem = schema[i]?.fields.find((it) => {
        return it.value == item;
      });

      s[item] = true;

      o[checkItem.label] = {
        title: checkItem.label,
        dataIndex: checkItem.value,
        key: checkItem.value,
      };
    });

    setOptions({
      ...Options,
      [table]: s,
    });
    setColumns({
      ...columns,
      [table]: o,
    });
  };

  const handleColChange = (e, table, i) => {
    const o = {};
    e.forEach((item) => {
      const checkItem = schema[i]?.fields.find((it) => {
        return it?.value == item;
      });

      if (checkItem) {
        o[checkItem?.label] = {
          title: checkItem?.label,
          dataIndex: checkItem?.value,
          key: checkItem?.value,
        };
      }
    });

    return o;
  };

  const handleSubmit = async () => {
    // to remove {}
    let sendOptions = {};
    for (let i of Object.keys(Options)) {
      if (Options[i] != {}) {
        sendOptions[i] = {
          select: Options[i],
        };
      }
    }

    console.log(sendOptions);

    const finalCols = Options;
    for (let i of Object.keys(finalCols)) {
      if (Object.keys(finalCols[i]).length === 0) {
        delete finalCols[i];
      }
    }
    setOptions(finalCols);

    await handleFilter(sendOptions);
  };

  const handleSave = () => {
    const finalCols = Options;
    for (let i of Object.keys(finalCols)) {
      if (Object.keys(finalCols[i]).length === 0) {
        delete finalCols[i];
      }
    }
    setOptions(finalCols);
    localStorage.setItem(
      "studentStudentInformationOptionsS-edu-info",
      JSON.stringify(finalCols)
    );
    console.log(Options);
  };

  const handleLoad = () => {
    const op = JSON.parse(
      localStorage.getItem("studentStudentInformationOptionsS-edu-info")
    );
    const selTables = op && Object.keys(op);

    const setop = {};
    op &&
      selTables.map((table) => {
        const e = Object.keys(op[table]);
        const ind = schema.findIndex((item) => item.value === table);
        if (ind !== -1) {
          const o = handleColChange(e, table, ind);
          setop[table] = o;
        }
      });

    setColumns(setop);

    setSelectedTables(Object.keys(op));
    setOptions(op);
  };

  const handleReset = () => {
    setColumns({});
    setOptions({});
    setSelectedTables([]);
  };

  useEffect(() => {
    console.log("Options: ", Options);
    console.log("Columns: ", columns);
  }, [Options, columns]);

  useEffect(() => {
    const finalCols = {};
    selectedTables.forEach((item) => {
      if (Options && Options[item]) finalCols[item] = Options[item];
    });

    if (Object.keys(finalCols).length === 0) {
      setOptions(defaultop);
    } else {
      setOptions(finalCols);
    }

    const setop = {};
    const p = Object.keys(finalCols);
    p.length !== 0 &&
      finalCols &&
      p.forEach((table) => {
        const e = Object.keys(finalCols[table]);
        const ind = schema.findIndex((item) => item?.value === table);
        if (ind !== -1) {
          const o = handleColChange(e, table, ind);
          setop[table] = o;
        }
      });

    setColumns(setop);
  }, [selectedTables]);

  useEffect(() => {
    console.log("Filtered Data:", FilteredSearchStudentInformation);
    console.log("Selected Edu:", selectedExp);
  }, [FilteredSearchStudentInformation, selectedExp]);

  useEffect(() => {
    const filteredResults = data?.filter((result) => {
      const nameMatch = result?.sid?.studentEmail
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase());
      return nameMatch;
    });
    console.log(filteredResults);
    if (!searchTerm) {
      setFilteredSearchStudentInformation(data);
    } else {
      setFilteredSearchStudentInformation(filteredResults);
    }
  }, [searchTerm, data]);

    useEffect(() => {
      console.log(topOne);
    }, [topOne]);

  if (loading) {
    return <Loader></Loader>;
  }


  const onChange = (checked) => {
  console.log(`switch to ${checked}`);
    settopOne((e)=>!e);
};




  return (
    <>
      <Divider />

      <strong>Select Student Experience</strong>
      <Select
        style={{ width: 200, margin: "1rem" }}
        placeholder='Select type'
        value={selectedExp}
        onChange={(value) => setSelectedExp(value)}>
        {/* Options for selecting type (student, faculty) */}
        {expInfo &&
          Object.keys(expInfo).map((item) => {
            return <Select.Option value={item}>{expInfo[item]}</Select.Option>;
          })}
      </Select>
      {selectedExp && (
        <>
          <Divider />
          <Switch checkedChildren="Select Recent One" unCheckedChildren="Select All" defaultChecked onChange={onChange} />
          <Divider />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}>
            <Checkbox
              style={{
                // margin: "10px",
                // marginLeft: "auto",
                color: "red",
                fontWeight: 900,
                marginRight: "auto",
              }}
              onChange={(e) => setAddMoreFields(e.target.checked)}>
              Add More Fields
            </Checkbox>

            <RangePicker
              value={dates || value}
              onCalendarChange={(val) => {
                setDates(val);
              }}
              onChange={(val) => {
                setValue(val);
              }}
              changeOnBlur
            />

            <Button
              type='primary'
              style={{ marginLeft: "1rem" }}
              onClick={handleSubmit}>
              Apply Filter
            </Button>
          </div>

          <Divider />
          <div>
            {/* <strong>Select Data Tables: </strong> */}

            {AddMoreFields && (
              <>
                <strong>Select Data Tables: </strong>
                <Checkbox
                  style={{ margin: "20px", color: "red", fontWeight: 900 }}
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}>
                  Check all
                </Checkbox>

                <Divider style={{ margin: "2px" }} />
                <Checkbox.Group
                  style={{ marginTop: "20px" }}
                  options={schema}
                  labelInValue={true}
                  value={selectedTables}
                  onChange={(values) => {
                    setSelectedTables(values);
                  }}></Checkbox.Group>

                <Divider style={{ margin: "20px" }} />
                {selectedTables.length !== 0 && (
                  <strong>Select Data Fields To Display In Table: </strong>
                )}
                <Collapse
                  style={{ marginTop: "20px" }}
                  items={selectedTables.map((table, i) => {
                    return {
                      key: i,
                      label:
                        schema[
                          schema.findIndex((item) => item.value === table)
                        ]["label"],
                      children: (
                        // return (
                        <div key={i}>
                          <Checkbox.Group
                            style={{ marginTop: "20px" }}
                            options={
                              schema.find((item) => item.value === table)
                                ?.fields || []
                            }
                            value={
                              Object.keys(Options.length !== 0) &&
                              Options &&
                              Options[table]
                                ? Object.keys(Options[table])
                                : []
                            }
                            onChange={(e) =>
                              handleFieldChange(
                                e,
                                table,
                                schema.findIndex((item) => item.value === table)
                              )
                            }></Checkbox.Group>
                        </div>
                        // );
                      ),
                    };
                  })}
                />
                <Divider style={{ margin: "20px" }} />
              </>
            )}
            {/* Form submission button */}
            <div className='action-buttons'>
              <Tooltip
                placement='top'
                title={<span>Load the previously saved fields</span>}>
                <Button type='primary' onClick={handleLoad}>
                  Load
                </Button>
              </Tooltip>
              <Tooltip placement='top' title={<span>Save the fields</span>}>
                <Button
                  className='action-button-save'
                  type='primary'
                  onClick={handleSave}>
                  Save
                </Button>
              </Tooltip>
              <Tooltip placement='top' title={<span>Reset the fields</span>}>
                <Button type='primary' onClick={handleReset}>
                  Reset
                </Button>
              </Tooltip>
              <Tooltip
                placement='top'
                title={<span>Submit to get the data</span>}>
                <Button
                  type='primary'
                  onClick={handleSubmit}
                  disabled={selectedBatch == "" ? true : false}>
                  Submit
                </Button>
              </Tooltip>
              <Input
                className='request-search-input'
                type='text'
                placeholder='Search by email'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Divider style={{ margin: "20px" }} />
          </div>
{topOne &&
          <MyTable
            title={Title + " - " + selectedExp}
            dataSource={FilteredSearchStudentInformation}
            columns={columns}
            type='experience'
            />}
{!topOne &&
          <Mytab
            title={Title + " - " + selectedExp}
            dataSource={FilteredSearchStudentInformation}
            columns={columns}
            />}

        </>
      )}
    </>
  );
};

export default Experience;
