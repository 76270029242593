import React, { createContext, useEffect, useState } from "react";
import { message } from "antd";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const warning = (message) => {
    messageApi.open({
      type: "warning",
      content: message,
    });
  };

  // const hodapiurl = "https://oriole-smiling-usefully.ngrok-free.app";
  const hodapiurl = "https://ecs-admin-backend.onrender.com";
  const certsapiurl = "https://oriole-smiling-usefully.ngrok-free.app";
  const uploadurl = "https://oriole-smiling-usefully.ngrok-free.app";
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
    const storedRole = localStorage.getItem("role");
    if (storedRole) {
      setUserRole(storedRole);
    }
    setIsLoading(false);
  }, []);

  const handleLogin = (token, role) => {
    setToken(token);
    setUserRole(role);
    localStorage.setItem("token", token);
    localStorage.setItem("role", role);
  };

  const handleLogout = () => {
    setToken(null);
    setUserRole(null);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
  };

  // useEffect(() => {
  //   console.log(token);
  // }, [token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <UserContext.Provider
      value={{
        handleLogin,
        handleLogout,
        token,
        userRole,
        hodapiurl,
        certsapiurl,
        success,
        error,
        warning,
        uploadurl,
      }}>
      {contextHolder}
      {children}
    </UserContext.Provider>
  );
};
