import React from 'react'
import { Tabs } from 'antd';
import Data from './Data';
import Main from '../Layouts/Main';
import StudentData from './StudentData';
import FacultyData from './FacultyData';


const onChange = (key) => {
  console.log(key);
};


const items = [
  {
    key: "1",
    label: "Student Data",
    children: <StudentData />,
  },
  {
    key: "2",
    label: "Faculty Data",
    children: <FacultyData />,
  },
  {
    key: "3",
    label: "Advanced Filter",
    children: <Data />,
  },
];


const MainData = () => {
    return (
      <Main >
    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </Main>
  )
}

export default MainData