import React,{ useRef, useEffect, useState } from "react";
import Main from "../Layouts/Main";
import { Button, Input, Modal, Table, message } from "antd";
import "./Data.css";
import { useAuth } from "../../useAuth";
import { Select, Checkbox, Form, DatePicker, Collapse, Divider } from "antd";
import { schema } from "./SchemaData";
import MyTable from "./MyTable"
import { Tooltip } from "antd";
import Loader from "../../Loader/Loader";



const Data = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { hodapiurl, token,success,error } = useAuth();
  const [FilteredSearchData, setFilteredSearchData] = useState([]);
  const [columns, setColumns] = useState({});

  const [selectedType, setSelectedType] = useState("");
  const [selectedTables, setSelectedTables] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [Options, setOptions] = useState({});

  const checkAll = schema.length === selectedTables.length;
  const indeterminate = selectedTables.length > 0 && selectedTables.length < schema.length;
  



  const fetchDetails = async (sendOptions) => {
      setLoading(true);
      console.log(Options)
      try {
        const response = await fetch(
          `${hodapiurl}/api/hod/student/list/condition`,
          {
            method: "POST",
            
            headers: {
              "Content-Type": "application/json",
              Authorization: "BEARER " + token,
            },
            body:JSON.stringify({options:sendOptions,year:selectedBatch}),
          }
        );
        const data = await response.json();
        console.log(data);

    if (data) {
           const transformedData = data.map((item, index) => ({
             key: index,
             ...item,
           }));
      
      setFilteredSearchData(transformedData);
      setData(transformedData);
         }
       
        success("Data Fetched Successfully")
        setLoading(false);
      } catch (err) {
        error(`Error fetching Results`);
        setLoading(false);
      }
    };
  
  
  const onCheckAllChange = (e) => {
    const sc = schema.map((item) => {
      return item.value;
    });
    setSelectedTables(e.target.checked ? sc : []);
  };

  // Function to fetch fields for a selected table
  const handleFieldChange = (e, table, i) => {
    const s = {};
    const o = {};
    e.forEach((item) => {
      const checkItem = schema[i]?.fields.find((it) => {
        return it.value == item;
      });

      s[item] = true;

      o[checkItem?.label] = {
        title: checkItem.label,
        dataIndex: checkItem.value,
        key: checkItem.value,
      };
    });
    setOptions({
      ...Options,
      [table]: s,
    });
    setColumns({
      ...columns,
      [table]: o,
    });
  };

  // Function to fetch fields for a selected table
  const handleColChange = (e, table, i) => {
    const o = {};
    e.forEach((item) => {
      const checkItem = schema[i]?.fields.find((it) => {
        return it.value == item;
      });

      o[checkItem.label] = {
        title: checkItem.label,
        dataIndex: checkItem.value,
        key: checkItem.value,
      };
      // }
    });

    return o;
  };

  const handleSubmit = async() => {

    let sendOptions = {};
    for (let i of Object.keys(Options)) {
      if (Options[i] != {}) { 
        sendOptions[i]={
          select:Options[i]
        }
      }
    }

    console.log(sendOptions);

    const finalCols = Options;
    for (let i of Object.keys(finalCols)) {
      if (Object.keys(finalCols[i]).length === 0) {
        delete finalCols[i];
      }
    }
    setOptions(finalCols);

    await fetchDetails(sendOptions);
  };

  const handleSave = () => {
    const finalCols = Options;
    for (let i of Object.keys(finalCols)) {
      if (Object.keys(finalCols[i]).length === 0) {
        delete finalCols[i];
      }
    }
    setOptions(finalCols);
    localStorage.setItem("selectedType", selectedType);
    localStorage.setItem("studentDataOptions", JSON.stringify(finalCols));
    console.log(Options);
  };

  const handleLoad = () => {
    const st = localStorage.getItem("selectedType");
    setSelectedType(st);
    const op = JSON.parse(localStorage.getItem("studentDataOptions"));
    const selTables = op && Object.keys(op);

    const setop = {}
    op && selTables.map((table) => {
      const e = Object.keys(op[table]);
      const ind = schema.findIndex((item) => item.value == table);
      if (ind != -1) {
        
        const o = handleColChange(
          e,
          table,
          ind
        );
        setop[table] = o;
        }
    })

    setColumns(setop);

    setSelectedTables(Object.keys(op));
    setOptions(op);
  };

  useEffect(() => {
    console.log(Options);
    console.log(columns);
  }, [Options, columns]);
  
  
  useEffect(() => {
    console.log(FilteredSearchData);
  }, [FilteredSearchData]);
  
  useEffect(() => {
    const finalCols = {};
    selectedTables.forEach((item) => {
      if(Options && Options[item])
      finalCols[item] = Options[item];
    })

    console.log(finalCols)
    setOptions(finalCols);

    const setop = {};
    const p = Object.keys(finalCols);
    p.length!==0 && finalCols &&
      p.map((table) => {
        const e = Object.keys(finalCols[table]);
        const ind = schema.findIndex((item) => item?.value == table);
        if (ind != -1) {
          const o = handleColChange(e, table, ind);
          setop[table] = o;
        }
      });

    setColumns(setop);

  }, [selectedTables]);

  useEffect(() => {
    const filteredResults = data?.filter((result) => {
      const nameMatch = result?.studentEmail
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase());
      return nameMatch;
    });
    console.log(filteredResults)
    if (filteredResults.length == 0) {
      setFilteredSearchData(data)
    }
    else { 
      setFilteredSearchData(filteredResults);
    }
  }, [searchTerm, data]);

  if (loading) {
    return <Loader></Loader>;
  }



  return (
    <div className='data-div'>
      <div className='section-title'>Data</div>

      <div>
        <strong>Select Data: ( Student/Faculty)</strong>
        <Select
          style={{ width: 200, margin: "1rem" }}
          placeholder='Select type'
          onChange={(value) => setSelectedType(value)}>
          {/* Options for selecting type (student, faculty) */}
          <Select.Option value='student'>Student</Select.Option>
          <Select.Option value='faculty'>Faculty</Select.Option>
        </Select>

        <strong>Select Batch</strong>
        <Select
          style={{ width: 200, margin: "1rem" }}
          placeholder='Select type'
          onChange={(value) => setSelectedBatch(value)}>
          {/* Options for selecting type (student, faculty) */}
          <Select.Option value='2020'>2020-2024</Select.Option>
          <Select.Option value='2021'>2021-2025</Select.Option>
        </Select>
        <Divider style={{ margin: "2px" }} />
        {selectedType && selectedType === "student" && (
          <>
            <strong>Select Data Tables: </strong>
            <Checkbox
              style={{ margin: "20px", color: "red", fontWeight: 900 }}
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}>
              Check all
            </Checkbox>

            <Divider style={{ margin: "2px" }} />
            <Checkbox.Group
              style={{ marginTop: "20px" }}
              options={schema}
              labelInValue={true}
              value={selectedTables}
              onChange={(values) => {
                setSelectedTables(values);
              }}></Checkbox.Group>

            <Divider style={{ margin: "20px" }} />
            <strong>Select Data Fields To Display In Table: </strong>
            <Collapse
              style={{ marginTop: "20px" }}
              items={selectedTables.map((table, i) => {
                return {
                  key: i,
                  label:
                    schema[schema.findIndex((item) => item.value == table)][
                      "label"
                    ],
                  children: (
                    // return (
                    <div key={i}>
                      <Checkbox.Group
                        style={{ marginTop: "20px" }}
                        options={
                          schema.find((item) => item.value == table)?.fields ||
                          []
                        }
                        value={
                          Options != {} && Options && Options[table]
                            ? Object.keys(Options[table])
                            : []
                        }
                        onChange={(e) =>
                          handleFieldChange(
                            e,
                            table,
                            schema.findIndex((item) => item.value == table)
                          )
                        }></Checkbox.Group>
                    </div>
                    // );
                  ),
                };
              })}
            />
            <Divider style={{ margin: "20px" }} />

            {/* Form submission button */}
            <div className='action-buttons'>
              <Tooltip
                placement='top'
                title={<span>Submit to get the data</span>}>
                <Button type='primary' onClick={handleSubmit}>
                  Submit
                </Button>
              </Tooltip>
              <Tooltip
                placement='top'
                title={<span>Load the previously saved fields</span>}>
                <Button type='primary' onClick={handleLoad}>
                  Load
                </Button>
              </Tooltip>
              <Tooltip placement='top' title={<span>Save the fields</span>}>
                <Button
                  className='action-button-save'
                  type='primary'
                  onClick={handleSave}>
                  Save
                </Button>
              </Tooltip>
              <Input
                className='request-search-input'
                type='text'
                placeholder='Search by student email'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Divider style={{ margin: "20px" }} />
          </>
        )}
      </div>

      <MyTable dataSource={FilteredSearchData} columns={columns} />
    </div>
  );
};

export default Data;
