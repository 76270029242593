import React, { useState } from "react";
import Main from "../Layouts/Main";
import { Button, Upload, Table, Divider } from "antd";
import { useAuth } from "../../useAuth";
import Papa from "papaparse";
import { InboxOutlined } from "@ant-design/icons";


const StudentCenter = () => {
  const { success, error ,hodapiurl,token} = useAuth();
  const [parsedData, setParsedData] = useState([]);

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
    


 const handleUpload = async (file) => {
   Papa.parse(file, {
     header: true,
     skipEmptyLines: true,
     complete: function (results) {
       const rowsArray = [];
       const valuesArray = [];
        console.log(results)
       results.data.forEach((d) => {
         rowsArray.push(Object.keys(d));
         valuesArray.push(Object.values(d));
       });

       setParsedData(results.data);
       setTableColumns(rowsArray[0]);
       setTableData(results.data);
     },
   });
 };

    
  const handleSendData = async (final) => {
    try {
      const response = await fetch(`${hodapiurl}/api/hod/students/email/c`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "BEARER " + token,
        },
        body: JSON.stringify(final),
      });

      if (response.ok) {
        const responseData = await response.json();
          console.log("Response from server:", responseData);
          success("student logins created successfully and mails sent")
        // Handle the response data as needed
      } else {
        error("Error while creating students and sending mails");
      }
    } catch (err) {
        error("Failed to send data.");
      console.error("Error:", err.message);
    }
    };



    const columns = tableColumns.map((column, index) => {
        console.log(column,index)
        console.log(typeof column)
        const d = column.toLowerCase().trim();
        if (
          [
            "email",
            "emailid",
            "studentemail",
            "emailaddress",
            "email address",
            "email id",
          ].includes(d)
        ) {
          return {
            title: column,
            key: "studentEmail",
            dataIndex: column,
          };
        }
        if (
          ["phone","studentphone" ,"phone number", "phone num"].includes(d)
        ) {
          return {
            title: column,
            key: "studentPhone",
            dataIndex: column,
          };
        }
        if (["roll", "rollnumber","studentroll" ,"roll number", "rollnum", "id", "roll num"].includes(d)) {
          return {
            title: column,
            key: "rollNumber",
            dataIndex: column,
          };  
        }

        if (
          [
            "yoa",
            "year",
            "year of admission",
            "yearofadmission",
            "yearof admission",
            "yearof admision",
          ].includes(d)
        ) {
          return {
            title: column,
            key: "yearOfAdmission",
            dataIndex: column,
          };
        }

    });
    


    
    const handlebeforecall = async () => {
        const heads = {};
        columns.forEach((item) => {
            heads[item.key] = item.dataIndex;
        });

        const final = tableData.map((item) => {
    
            return {
                studentEmail: item[heads["studentEmail"]],
                yearOfAdmission: item[heads["yearOfAdmission"]],
                rollNumber: item[heads["rollNumber"]],
                studentPhone: item[heads["studentPhone"]],
            };
        });

        console.log(final)
        await handleSendData(final)
        
    };

    
     const beforeUpload = (file) => {
       handleUpload(file);
       return false; // Prevent default upload behavior
     };

  return (
    <>
      <Divider />

      <h2 className='section-title'>Invite Students</h2>
      <h4>
        Give a csv file with fields : rollnumber, email, phone, yearofadmission{" "}
      </h4>
      <h5 style={{ color: "red" }}>Make sure the header is same as above </h5>
      <div>
        <Upload.Dragger
          beforeUpload={beforeUpload}
          onDrop={(e) => {
            // console.log("Dropped files", e.dataTransfer.files);
          }}
          showUploadList={false}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <Button type='primary'>Upload CSV</Button>
        </Upload.Dragger>
      </div>

      {parsedData.length > 0 && (
        <>
          <Button
            style={{ margin: "1rem" }}
            type='primary'
            onClick={handlebeforecall}>
            Send Data
          </Button>
          <Table dataSource={tableData} columns={columns} />
        </>
      )}
    </>
  );
};

export default StudentCenter;
