import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Table, message } from "antd";
import "./StudentInfo.css";
import { useAuth } from "../../../useAuth";
import { Select, Checkbox, Collapse, Divider } from "antd";
import { schema } from "../schemas/InformationSchema";
import MyTable from "../MyTable";
import { Tooltip } from "antd";
import Loader from "../../../Loader/Loader";

const StudentInformation = ({ title, batch }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { hodapiurl, token, success, error } = useAuth();
  const [Title, setTitle] = useState("");
  const [
    FilteredSearchStudentInformation,
    setFilteredSearchStudentInformation,
  ] = useState([]);

  const [AddMoreFields, setAddMoreFields] = useState("");
  const [selectedBatch, setSelectedBatch] = useState("");
  const [selectedTables, setSelectedTables] = useState([]);
  const [columns, setColumns] = useState({});
  const [Options, setOptions] = useState({});



    useEffect(() => {
      const op = {
        Student: {
          rollNumber: true,
        },
        sid: {
          fullName: true,
          yearOfAdmission: true,
          department: true,
          programCode: true,
          admittedUnder: true,
          program: true,
          rank: true,
        },
      };

      setOptions(op);
      setSelectedTables(["sid", "Student"]);
    }, []);

  useEffect(() => {
    setTitle(title);
    setSelectedBatch(batch);
    console.log(Title, batch);
  }, [title, batch]);

  const checkAll = schema.length === selectedTables.length;
  const indeterminate =
    selectedTables.length > 0 && selectedTables.length < schema.length;

  const fetchDetails = async (sendOptions) => {
    setLoading(true);
    console.log("Batch: ",selectedBatch);
    console.log(Options);
    try {
      const response = await fetch(
        `${hodapiurl}/api/hod/student/list/condition`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
            Authorization: "BEARER " + token,
          },
          body: JSON.stringify({ options: sendOptions, year: selectedBatch }),
        }
      );
      const data = await response.json();
      console.log(data);

      if (data) {
        const transformedData = data.map((item, index) => {
          return {
            key: index,
            ...item,
            sid: {
              ...item["sid"],
              College: item?.sid?.College?.collegeName,
              university: item?.sid?.university?.universityName,
              department: item?.sid?.department?.departmentName,
              programCode: item?.sid?.program?.programCode,
              program: item?.sid?.program?.programName,
              community: item?.sid?.community?.communityName,
              category: item?.sid?.category?.categoryName,
              admittedUnder: item?.sid?.admittedUnder?.examName,
            },
          };
        });

        setData(transformedData);
        setFilteredSearchStudentInformation(transformedData);
      }

      success("StudentInformation Fetched Successfully");
      setLoading(false);
    } catch (err) {
      error(`Error fetching Results`);
      setLoading(false);
    }
  };

  const onCheckAllChange = (e) => {
    const sc = schema.map((item) => {
      return item.value;
    });
    setSelectedTables(e.target.checked ? sc : []);
  };

  const handleFieldChange = (e, table, i) => {
    const s = {};
    const o = {};
    e.forEach((item) => {
      const checkItem = schema[i]?.fields.find((it) => {
        return it.value == item;
      });

      s[item] = true;

      o[checkItem.label] = {
        title: checkItem.label,
        dataIndex: checkItem.value,
        key: checkItem.value,
      };
    });

    setOptions({
      ...Options,
      [table]: s,
    });
    setColumns({
      ...columns,
      [table]: o,
    });
  };

  const handleColChange = (e, table,i) => {
    const o = {};
    e.forEach((item) => {
      const checkItem = schema[i]?.fields.find((it) => {
        return it?.value == item;
      });

      if (checkItem) {
        o[checkItem?.label] = {
          title: checkItem?.label,
          dataIndex: checkItem?.value,
          key: checkItem?.value,
        };
      }
    });

    return o;
  };

  const handleSubmit = async () => {
    // to remove {}
    let sendOptions = {};
    for (let i of Object.keys(Options)) {
      if (Options[i] != {}) {
        sendOptions[i] = {
          select: Options[i],
        };
      }
    }

    console.log(sendOptions);

    const finalCols = Options;
    for (let i of Object.keys(finalCols)) {
      if (Object.keys(finalCols[i]).length === 0) {
        delete finalCols[i];
      }
    }
    setOptions(finalCols);

    await fetchDetails(sendOptions);
  };

  const handleSave = () => {
    const finalCols = Options;
    for (let i of Object.keys(finalCols)) {
      if (Object.keys(finalCols[i]).length === 0) {
        delete finalCols[i];
      }
    }
    setOptions(finalCols);
    localStorage.setItem(
      "studentStudentInformationOptionsS-basic-info",
      JSON.stringify(finalCols)
    );
    console.log(Options);
  };

  const handleLoad = () => {
    const op = JSON.parse(
      localStorage.getItem("studentStudentInformationOptionsS-basic-info")
    );
    const selTables = op && Object.keys(op);

    const setop = {};
    op &&
      selTables.map((table) => {
        const e = Object.keys(op[table]);
        const ind = schema.findIndex((item) => item.value == table);
        if (ind != -1) {
          const o = handleColChange(e,table, ind);
          setop[table] = o;
        }
      });

    setColumns(setop);

    setSelectedTables(Object.keys(op));
    setOptions(op);
  };

  const handleReset = () => {
    setColumns({});
    setOptions({});
    setSelectedTables([]);
  };

  useEffect(() => {
    console.log("Options: ", Options);
    console.log("Columns: ", columns);
  }, [Options, columns]);

  useEffect(() => {
    console.log(selectedTables)
    console.log(Options)
    const finalCols = {};
    selectedTables.forEach((item) => {
      if (Options && Options[item]) finalCols[item] = Options[item];
    });

    if (Object.keys(finalCols).length === 0) {
      setOptions({
        Student: {
          rollNumber: true,
        },
        sid: {
          fullName: true,
          yearOfAdmission: true,
          department: true,
          programCode: true,
          admittedUnder: true,
          program: true,
          rank: true,
        },
      });
    }
    else { 
      setOptions(finalCols);
    }

    const setop = {};
    const p = Object.keys(finalCols);
    p.length !== 0 &&
      finalCols &&
      p.forEach((table) => {
        const e = Object.keys(finalCols[table]);
        const ind = schema.findIndex((item) => item?.value === table);
        if (ind !== -1) {
          const o = handleColChange(e, table, ind);
          setop[table] = o;
        }
      });

    setColumns(setop);
  }, [selectedTables]);

  useEffect(() => {
    console.log("Filtered Data:",FilteredSearchStudentInformation);
  }, [FilteredSearchStudentInformation]);



  useEffect(() => {
    const filteredResults = data?.filter((result) => {
      const nameMatch = result?.sid?.studentEmail
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase());
      return nameMatch;
    });
    console.log(filteredResults);
    if (!searchTerm) {
      setFilteredSearchStudentInformation(data);
    } else {
      setFilteredSearchStudentInformation(filteredResults);
    }
  }, [searchTerm, data]);

  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <>
      <Divider />

      <Checkbox
        style={{
          margin: "30px",
          marginLeft: "auto",
          color: "red",
          fontWeight: 900,
        }}
        onChange={(e) => setAddMoreFields(e.target.checked)}>
        Add More Fields
      </Checkbox>
      <div>
        {/* <strong>Select Data Tables: </strong> */}

        <Divider />

        {AddMoreFields && (
          <>
            <strong>Select Data Tables: </strong>
            <Checkbox
              style={{ margin: "20px", color: "red", fontWeight: 900 }}
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}>
              Check all
            </Checkbox>

            <Divider style={{ margin: "2px" }} />
            <Checkbox.Group
              style={{ marginTop: "20px" }}
              options={[schema[0], schema[1]]}
              labelInValue={true}
              value={selectedTables}
              onChange={(values) => {
                setSelectedTables(values);
              }}></Checkbox.Group>

            <Divider style={{ margin: "20px" }} />
            {selectedTables.length !== 0 && (
              <strong>Select Data Fields To Display In Table: </strong>
            )}
            <Collapse
              style={{ marginTop: "20px" }}
              items={selectedTables.map((table, i) => {
                return {
                  key: i,
                  label:
                    schema[schema.findIndex((item) => item.value === table)][
                      "label"
                    ],
                  children: (
                    // return (
                    <div key={i}>
                      <Checkbox.Group
                        style={{ marginTop: "20px" }}
                        options={
                          schema.find((item) => item.value === table)?.fields ||
                          []
                        }
                        value={
                          Object.keys(Options.length !== 0) &&
                          Options &&
                          Options[table]
                            ? Object.keys(Options[table])
                            : []
                        }
                        onChange={(e) =>
                          handleFieldChange(
                            e,
                            table,
                            schema.findIndex((item) => item.value === table)
                          )
                        }></Checkbox.Group>
                    </div>
                    // );
                  ),
                };
              })}
            />
            <Divider style={{ margin: "20px" }} />
          </>
        )}
        {/* Form submission button */}
        <div className='action-buttons'>
          <Tooltip
            placement='top'
            title={<span>Load the previously saved fields</span>}>
            <Button type='primary' onClick={handleLoad}>
              Load
            </Button>
          </Tooltip>
          <Tooltip placement='top' title={<span>Save the fields</span>}>
            <Button
              className='action-button-save'
              type='primary'
              onClick={handleSave}>
              Save
            </Button>
          </Tooltip>
          <Tooltip placement='top' title={<span>Reset the fields</span>}>
            <Button type='primary' onClick={handleReset}>
              Reset
            </Button>
          </Tooltip>
          <Tooltip placement='top' title={<span>Submit to get the data</span>}>
            <Button
              type='primary'
              onClick={handleSubmit}
              disabled={selectedBatch == "" ? true : false}>
              Submit
            </Button>
          </Tooltip>
          <Input
            className='request-search-input'
            type='text'
            placeholder='Search by email'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <Divider style={{ margin: "20px" }} />
      </div>

      <MyTable
        title={Title}
        dataSource={FilteredSearchStudentInformation}
        columns={columns}
      />
    </>
  );
};

export default StudentInformation;
