import React from 'react'
import Main from '../Layouts/Main'
import { Card, Progress, Table } from 'antd';
import './Home.css';

const HOD_HOME = () => {
  const timetable_columns = [
		{
			title: "Duration",
			dataIndex: "duration",
		},
		{
			title: "Commencement 1st SEM Classwork",
			dataIndex: "duration_1",
		},
		{
			title: "1st Mid Examinations",
			dataIndex: "duration_2",
		},
		{
			title: "2nd Mid Examinations",
			dataIndex: "duration_3",
		},
		{
			title: "End Semester Exams",
			dataIndex: "duration_4",
		},
		{
			title: "Commencement of 2nd SEM Classwork",
			dataIndex: "duration_5",
		},
	];

const timetable_data = [
	{
		key: "1",
		duration: "1st Year",
		duration_1: "01-01-2023 To 28-02-2023",
		duration_2: "15-03-2023 To 30-04-2023",
		duration_3: "10-05-2023 To 20-06-2023",
		duration_4: "01-07-2023 To 15-08-2023",
		duration_5: "05-09-2023 To 31-12-2023",
	},
	{
		key: "2",
		duration: "2nd Year",
		duration_1: "01-01-2023 To 28-02-2023",
		duration_2: "15-03-2023 To 30-04-2023",
		duration_3: "10-05-2023 To 20-06-2023",
		duration_4: "01-07-2023 To 15-08-2023",
		duration_5: "05-09-2023 To 31-12-2023",
	},
	{
		key: "3",
		duration: "3rd Year",
		duration_1: "01-01-2023 To 28-02-2023",
		duration_2: "15-03-2023 To 30-04-2023",
		duration_3: "10-05-2023 To 20-06-2023",
		duration_4: "01-07-2023 To 15-08-2023",
		duration_5: "05-09-2023 To 31-12-2023",
	},
	{
		key: "4",
		duration: "4th Year",
		duration_1: "01-01-2023 To 28-02-2023",
		duration_2: "15-03-2023 To 30-04-2023",
		duration_3: "10-05-2023 To 20-06-2023",
		duration_4: "01-07-2023 To 15-08-2023",
		duration_5: "05-09-2023 To 31-12-2023",
	},
];


  return (
		<Main>
			<div>
				<div className="years-info">
					<div className="final-year">
						<div>
							<div className="year">4th Year</div>
							<div className="dot"></div>
						</div>

						<div class="status">
							<div class="stat">Total</div>
							<div class="stat-value">50</div>
						</div>

						<Progress
							strokeColor="#64DD59"
							trailColor="#D9D9D9"
							percent={100}
							showInfo={false}
						/>

						<div class="status">
							<div class="stat">Registered</div>
							<div class="stat-value">20</div>
						</div>

						<Progress
							strokeColor="red"
							trailColor="#D9D9D9"
							percent={(20 / 50) * 100}
							showInfo={false}
						/>
					</div>
					<div className="third-year">
						<div>
							<div className="year">3rd Year</div>
							<div className="dot"></div>
						</div>

						<div class="status">
							<div class="stat">Total</div>
							<div class="stat-value">50</div>
						</div>

						<Progress
							strokeColor="#64DD59"
							trailColor="#D9D9D9"
							percent={100}
							showInfo={false}
						/>

						<div class="status">
							<div class="stat">Registered</div>
							<div class="stat-value">20</div>
						</div>

						<Progress
							strokeColor="red"
							trailColor="#D9D9D9"
							percent={(20 / 50) * 100}
							showInfo={false}
						/>
					</div>
					<div className="second-year">
						<div>
							<div className="year">2nd Year</div>
							<div className="dot"></div>
						</div>

						<div class="status">
							<div class="stat">Total</div>
							<div class="stat-value">50</div>
						</div>

						<Progress
							strokeColor="#64DD59"
							trailColor="#D9D9D9"
							percent={100}
							showInfo={false}
						/>

						<div class="status">
							<div class="stat">Registered</div>
							<div class="stat-value">20</div>
						</div>

						<Progress
							strokeColor="red"
							trailColor="#D9D9D9"
							percent={(20 / 50) * 100}
							showInfo={false}
						/>
					</div>
					<div className="first-year">
						<div>
							<div className="year">1st Year</div>
							<div className="dot"></div>
						</div>

						<div class="status">
							<div class="stat">Total</div>
							<div class="stat-value">50</div>
						</div>

						<Progress
							strokeColor="#64DD59"
							trailColor="#D9D9D9"
							percent={100}
							showInfo={false}
						/>

						<div class="status">
							<div class="stat">Registered</div>
							<div class="stat-value">20</div>
						</div>

						<Progress
							strokeColor="red"
							trailColor="#D9D9D9"
							percent={(20 / 50) * 100}
							showInfo={false}
						/>
					</div>
				</div>
				<div className="timetable">
					<Card className='timetablecard'>
						<Table
              bordered
							className="tt"
							columns={timetable_columns}
							dataSource={timetable_data}
						/>
					</Card>
				</div>
			</div>
		</Main>
	);
}

export default HOD_HOME;