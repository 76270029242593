import React, { useContext, useEffect, useState } from "react";
import { Upload, Button, message, Space, Card, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Main from "../Layouts/Main";
import { UserContext } from "../../UserContext";
import './Style.css';
import notfound from "../../Images/notfound.jpg";

const Timetable = () => {
	const currentYear = new Date().getFullYear();
	const [file1, setFile1] = useState(null);
	const [file2, setFile2] = useState(null);
	const [file3, setFile3] = useState(null);
	const [file4, setFile4] = useState(null);
	const [url1, setUrl1] = useState("");
	const [url2, setUrl2] = useState("");
	const [url3, setUrl3] = useState("");
	const [url4, setUrl4] = useState("");
	const [loading, setLoading] = useState(false);
	const { token, uploadurl } = useContext(UserContext);

	const handleSubmit = async (endYear, file) => {
		try {
			if (!file) {
				message.error("Please upload a file");
				return;
			}
			console.log(file);
			console.log(endYear);

			const formData = new FormData();
			formData.append("yop", endYear);
			formData.append("timetable", file);
			console.log(formData);

			const response = await fetch(`${uploadurl}/upload/timetable`, {
				method: "POST",
				body: formData,
				headers: {
					Authorization: "BEARER " + token,
				},
			});

			if (response.ok) {
				const data = response.json();
				if (data.status === "error") {
					message.error("An unexpected Error Occured Please try again");
					return;
				} else {
					if (endYear === currentYear) setFile1(null);
					else if (endYear === currentYear + 1) setFile2(null);
					else if (endYear === currentYear + 2) setFile3(null);
					else if (endYear === currentYear + 3) setFile4(null);

					message.success("File uploaded successfully");
				}
			} else {
				message.error("Error uploading file");
			}
		} catch (error) {
			message.error("Error uploading file");
		}
	};

	const handleGet = async (endYear) => {
		try {
			console.log(endYear);
			const response = await fetch(`${uploadurl}/upload/timetable/${endYear}`, {
				method: "GET",
				headers: {
					Authorization: "BEARER " + token,
				},
			});

			if (response.ok) {
				const data = await response.json();
				if (data.status === "error") {
					message.error("An unexpected Error Occured Please try again");
					return;
				} else {
					return uploadurl + "/" + data.uri;
				}
			} else {
				message.error("Network Error Please Try Again");
			}
		} catch (error) {
			message.error("Network Error Please Try Again");
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const img1 = await handleGet(currentYear);
			setUrl1(img1);

			const img2 = await handleGet(currentYear + 1);
			setUrl2(img2);

			const img3 = await handleGet(currentYear + 2);
			setUrl3(img3);

			const img4 = await handleGet(currentYear + 3);
			setUrl4(img4);
		};

		fetchData();
	}, []);

	return (
		<Main>
			<div className="tt-main">
				<Card
					title={`${currentYear - 4} - ${currentYear} Batch`}
					className="tt-item">
					<Image fallback={notfound} src={url1}></Image>
					<div className="upbtn">
						<Upload
							maxCount={1}
							beforeUpload={(file) => {
								const isSizeValid = file.size / 1024 / 1024 < 2;
								if (!isSizeValid) {
									message.error("File size must be less than 2MB");
									return false;
								}
								setFile1(file);
								return false;
							}}>
							<Button icon={<UploadOutlined />}>Update File</Button>
						</Upload>
						<Button
							type="primary"
							onClick={() => handleSubmit(currentYear, file1)}
							disabled={!file1}>
							Submit
						</Button>
					</div>
				</Card>
				<Card
					title={`${currentYear - 3} - ${currentYear + 1} Batch`}
					className="tt-item">
					<Image fallback={notfound} src={url2}></Image>
					<div className="upbtn">
						<Upload
							maxCount={1}
							beforeUpload={(file) => {
								const isSizeValid = file.size / 1024 / 1024 < 2;
								if (!isSizeValid) {
									message.error("File size must be less than 2MB");
									return false;
								}
								setFile2(file);
								return false;
							}}>
							<Button icon={<UploadOutlined />}>Update File</Button>
						</Upload>
						<Button
							type="primary"
							onClick={() => handleSubmit(currentYear + 1, file2)}
							disabled={!file2}>
							Submit
						</Button>
					</div>
				</Card>
				<Card
					title={`${currentYear - 2} - ${currentYear + 2} Batch`}
					className="tt-item">
					<Image fallback={notfound} src={url3}></Image>
					<div className="upbtn">
						<Upload
							maxCount={1}
							beforeUpload={(file) => {
								const isSizeValid = file.size / 1024 / 1024 < 2;
								if (!isSizeValid) {
									message.error("File size must be less than 2MB");
									return false;
								}
								setFile3(file);
								return false;
							}}>
							<Button icon={<UploadOutlined />}>Update File</Button>
						</Upload>
						<Button
							type="primary"
							onClick={() => handleSubmit(currentYear + 2, file3)}
							disabled={!file3}>
							Submit
						</Button>
					</div>
				</Card>
				<Card
					title={`${currentYear - 1} - ${currentYear + 3} Batch`}
					className="tt-item">
					<Image fallback={notfound} src={url4}></Image>
					<div className="upbtn">
						<Upload
							maxCount={1}
							beforeUpload={(file) => {
								const isSizeValid = file.size / 1024 / 1024 < 2;
								if (!isSizeValid) {
									message.error("File size must be less than 2MB");
									return false;
								}
								setFile4(file);
								return false;
							}}>
							<Button icon={<UploadOutlined />}>Update File</Button>
						</Upload>

						<Button
							type="primary"
							onClick={() => handleSubmit(currentYear + 3, file4)}
							disabled={!file4}>
							Submit
						</Button>
					</div>
				</Card>
			</div>
		</Main>
	);
};

export default Timetable;
