import React from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Result } from "antd";
import Main from "./Components/HOD/Layouts/Main";
const UnderProgress = () => (
  <Main>
    <Result
      style={{
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      icon={<SmileOutlined />}
      title='Under Progress - Comming Soon!!'
    />
  </Main>
);
export default UnderProgress;
