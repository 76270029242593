import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Select, Form, Input, Card, message } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { UserContext } from "../UserContext";
import "./auth.css";
import logo from "../../assets/jntugv.png"

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiuri, setApiuri] = useState("");
  const { token, userRole, hodapiurl, handleLogin, success, error } =
    useContext(UserContext);

  const [role, setRole] = useState(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // useEffect(() => {
  //   if (role === "HOD") {
  //     setApiuri(hodapiurl);
  //   }
  // }, [role, studentapiuri, instructorapiuri, hodapiurl]);

  const handleAuthentication = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      console.log(values);
      values.collegeId = "JNTUGVCEV";
      values.universityId = "JNTUGV";
      values.departmentId = "CSE";
      // values.email = "csehod@gmail.com";
      // values.password = "csejntugv@123";

      let url;
      if (role == "HOD") {
        url = "/auth/hodlogin/";
      }
      if (role == "HODHELPER") {
        url = "/auth/adminlogin/hodhelper";
      }

      const response = await fetch(`${hodapiurl}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();
      if (data.status === "success" && data.token) {
        success("Login Successful");
        handleLogin(data.token, values.role);
      } else {
        error("Invalid Credentials");
      }

      if (role === "HOD") {
        navigate("/hod/home");
      } else if (role === "ADMIN") {
        navigate("/student/home");
      } else if (userRole === "HODHELPER") {
        navigate("/hod-helper/home");
      }
    } catch (err) {
      error("Invalid Credentials");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token && token !== "undefined" && token !== "null") {
      if (userRole === "HOD") {
        navigate("/hod/home");
      } else if (userRole === "ADMIN") {
        navigate("/student/home");
      } else if (userRole === "HODHELPER") {
        navigate("/hod-helper/home");
      }
    }
  }, [token, userRole]);

  return (
    <>
      {
        <div className='bg signup-comp'>
          <div className='div-card-lr'>
            <div className='left-card'>
              <img src={logo} className='logo-left' alt='logo' srcset='' />
              <div style={{ textAlign: "center" }}>
                <h2>JNTUGV</h2>
                <h2>Admin Portal Login</h2>
              </div>
              <p class='small'>
                The university is spread across six districts i.e
                Vizianagaram, Visakhapatnam, Srikakulam, Parvathipuram Manyam,
                Alluri Sitharama Raju and Anakapalli. There are 2 constituent
                colleges and 37 affiliated colleges under its jurisdiction and
                catering education in different Engineering, Pharmacy and
                Management departments.
              </p>
            </div>
            <Card className='innercard'>
              <div className='grid-2'>
                <div className='centerdflex'>
                  <center>
                    <h1>Login</h1>
                  </center>

                  <Form
                    form={form}
                    className='form-cont'
                    layout='vertical'
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={handleAuthentication}
                    autoComplete='off'>
                    <Form.Item
                      label='Email'
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: "Please enter the email!",
                        },
                      ]}>
                      <Input className='inp' />
                    </Form.Item>
                    <Form.Item
                      label='Role'
                      name='role'
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Role!",
                        },
                      ]}>
                      <Select
                        options={[
                          {
                            value: "HOD",
                            label: "HOD",
                          },
                          {
                            value: "EXAMADMIN",
                            label: "EXAM_ADMIN",
                          },
                          {
                            value: "ADMINISTRATIONADMIN",
                            label: "ADMINISTRATION_ADMIN",
                          },

                          {
                            value: "ALUMNIADMIN",
                            label: "ALUMNI_ADMIN",
                          },

                          {
                            value: "CERTIFICATEADMIN",
                            label: "CERTIFICATE_ADMIN",
                          },

                          {
                            value: "HODHELPER",
                            label: "HOD_OFFICE",
                          },
                          {
                            value: "PRINCIPAL",
                            label: "PRINCIPAL",
                          },
                          {
                            value: "PRINCIPALHELPER",
                            label: "PRINCIPAL_OFFICE",
                          },
                        ]}
                        onChange={(e) => setRole(e)}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Password'
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: "Please enter the password!",
                        },
                      ]}>
                      <Input
                        type={showPassword ? "text" : "password"}
                        className='inp'
                      />
                    </Form.Item>

                    <Button
                      type='text'
                      className='showbt'
                      icon={
                        showPassword ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )
                      }
                      onClick={togglePasswordVisibility}
                    />
                    <div className='forgotpass'>
                      <Link to='/forgot'>Forgot Password</Link>
                    </div>
                    <br />
                    {/* <div className="adjust">
									<label htmlFor="Login">Don't have an account? &nbsp;</label>
									<Link id="signup" to="/signup" className="a">
										Create One
									</Link>
								</div> */}
                    <br />
                    <Form.Item className='sub-btn'>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={loading}>
                        Login
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Card>
          </div>
        </div>
      }
    </>
  );
};

export default Login;
