export const batchInfo = {
  2020:"2020-2024",
  2021:"2021-2025",
  2022:"2022-2026",
  2023:"2023-2027",
  2024:"2024-2028",
};

export const typeInfo = {
  studentRegister: "Basic Details & Information",
  education: "Education Details",
  experience: "Experience Details",
};


export const eduInfo = {
  SECONDARY: "10th/HighSchool Details",
  DIPLOMA: "Diploma Details",
  INTERMEDIATE: "Intermediate Details",
  // UNDERGRADUATE: "UnderGraduation Details",
  POSTGRADUATE: "PostGradation Details",
  PHD: "Phd",
  PRIMARY: "Basic Details & Information",
};


export const expInfo = {
  JOB: "Job Details",
  INTERNSHIP: "Internship Details",
  APPRENTICESHIP: "Apprenticeship Details"
};

