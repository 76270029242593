import './App.css';
import { BrowserRouter as Router,Navigate, Route, Routes } from "react-router-dom";
import Forgot from './Components/OTHERS/Forgot';
import Login from './Components/userauth/login';
import ADMINISTRATION_ADMIN_HOME from './Components/ADMINSTRATION_ADMIN/Home/Home';
import ALUMINI_ADMIN_HOME from './Components/ALUMNI_ADMIN/Home/Home';
import CERTIFICATE_ADMIN_HOME from './Components/CERTIFICATE_ADMIN/Home/Home';
import EXAM_ADMIN_HOME from './Components/EXAM_ADMIN/Home/Home';
import HOD_HOME from './Components/HOD/Home/Home';
import HOD_HELPER_HOME from './Components/HOD_HELPER/Home/Home';
import Requests from './Components/HOD/Requests/Requests';
import Documents from './Components/HOD/documents/Documents';
import {AuthRoute} from "./Components/AuthRoute"
import MainData from './Components/HOD/Data/MainData';
import UnderProgress from './UnderProgress';
import ScMainData from './Components/HOD/studentcenter/ScMainData';
import Attendance from './Components/HOD_HELPER/Operations/Attendance';
import TimeTable from './Components/HOD_HELPER/Operations/TimeTable';
function App() {

  return (
		<Routes className="dd">
			{/* <Route path='/' element={<Login />} /> */}
			<Route path="/login" element={<Login />} />
			<Route path="/" element={<AuthRoute />}>
				<Route index element={<Login />} />
				<Route path="/forgot" element={<Forgot />} />
				<Route
					path="/adminstration-admin/home"
					element={<ADMINISTRATION_ADMIN_HOME />}
				/>
				<Route path="/alumni-admin/home" element={<ALUMINI_ADMIN_HOME />} />
				<Route
					path="/certificate-admin/home"
					element={<CERTIFICATE_ADMIN_HOME />}
				/>
				<Route path="/exam-admin/home" element={<EXAM_ADMIN_HOME />} />
				<Route path="/hod/home" element={<HOD_HOME />} />
				<Route path="/hod/data" element={<MainData />} />
				<Route path="/hod/requests" element={<Requests />} />
				<Route path="/hod/studentcenter" element={<ScMainData />} />
				<Route path="/hod/documents" element={<Documents />} />
				<Route path="/hod-helper/home" element={<HOD_HELPER_HOME />} />
				<Route path="/hod-helper/attendance" element={<Attendance />} />
				<Route path="/hod-helper/timetable" element={<TimeTable />} />
				<Route path="*" element={<UnderProgress />} />
			</Route>
		</Routes>
	);
}

export default App;