import React, { useEffect, useState } from "react";
import Main from "../Layouts/Main";
import { Button, Input, Modal, Table, message } from "antd";
import { useAuth } from "../../useAuth";
import { Select, Checkbox, Form, DatePicker, Collapse, Divider } from "antd";
import { schema } from "./SchemaData";
import MyTable from "./MyTable"
import Loader from "../../Loader/Loader";


const FacultyData = () => {
  const [facultyData, setFacultyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { hodapiurl, token,success,error } = useAuth();
  const [FilteredSearchFacultyData, setFilteredSearchFacultyData] = useState([]);
  const [columns, setColumns] = useState({});

  const [selectedType, setSelectedType] = useState("");
  const [selectedTables, setSelectedTables] = useState([]);
  // const [tablesFields, setTablesFields] = useState({});
  const [Options, setOptions] = useState({});

  const checkAll = schema.length === selectedTables.length;
  const indeterminate = selectedTables.length > 0 && selectedTables.length < schema.length;
  

  const fetchDetails = async (sendOptions) => {
      

        let data = [
          {
            rollNumber: "not-assigned",
            about: null,
            sid: {
              studentEmail: "mareedu.satyanarayana.5451@gmail.com",
              studentPhone: "+91 899502581",
            },
            volunteering: [],
            SkillRating: [],
          },
          {
            rollNumber: "not-assigned",
            about:
              "CSE graduate proficient in programming, software development, and problem-solving, actively seeking opportunities with the best company to leverage my skills and contribute to cutting-edge projects.",
            sid: {
              studentEmail: "mareedu.satyanarayana.5475@gmail.com",
              studentPhone: "+91 8919502589",
            },
            volunteering: [
              {
                organizationName: "ABC",
                description: "My personal portolio to showcase my skills",
                eventName: "xyz",
                level: "STATE",
              },
            ],
            SkillRating: [
              {
                rating: 4,
                years: "2",
              },
              {
                rating: 4,
                years: "2",
              },
              {
                rating: 3,
                years: "1",
              },
            ],
          },
          {
            rollNumber: "not-assigned",
            about: null,
            sid: {
              studentEmail: "mareedu.satyanarayana.51@gmail.com",
              studentPhone: "+91 899503281",
            },
            volunteering: [],
            SkillRating: [],
          },
        ];

      setLoading(true);
      console.log(Options)
      try {
        const response = await fetch(
          `${hodapiurl}/api/hod/student/list/condition`,
          {
            method: "POST",
            
            headers: {
              "Content-Type": "application/json",
              Authorization: "BEARER " + token,
            },
            body:JSON.stringify({options:sendOptions}),
          }
        );
        const facultyData = await response.json();
        console.log(facultyData);

      //    const finalFacultyData = [];
    if (facultyData) {
           const transformedFacultyData = facultyData.map((item, index) => ({
             key: index,
             ...item,
           }));
      
      
       
           setFilteredSearchFacultyData(transformedFacultyData);
         }
       
        success("FacultyData Fetched Successfully")
        setLoading(false);
      } catch (error) {
        error(`Error fetching Results`);
        setLoading(false);
      }
    };
  
  
  const onCheckAllChange = (e) => {
    // console.log(checkAll);
    // console.log(indeterminate);
    // console.log(schema);
    const sc = schema.map((item) => {
      return item.value;
    });
    setSelectedTables(e.target.checked ? sc : []);
  };

  // Function to fetch fields for a selected table
  const handleFieldChange = (e, table, i) => {
    // console.log(e);
    // console.log(i);
    const s = {};
    const o = {};
    e.forEach((item) => {
      const checkItem = schema[i]?.fields.find((it) => {
        return it.value == item;
      });

      s[item] = true;

      o[checkItem.label] = {
        title: checkItem.label,
        facultyDataIndex: checkItem.value,
        key: checkItem.value,
      };
      // }
    });
    // console.log(s);
    // console.log(o);
    setOptions({
      ...Options,
      [table]: s,
    });
    setColumns({
      ...columns,
      [table]: o,
    });
  };

  // Function to fetch fields for a selected table
  const handleColChange = (e, table, i) => {
    const o = {};
    e.forEach((item) => {
      const checkItem = schema[i]?.fields.find((it) => {
        return it.value == item;
      });

      o[checkItem.label] = {
        title: checkItem.label,
        facultyDataIndex: checkItem.value,
        key: checkItem.value,
      };
      // }
    });

    return o;
  };

  const handleSubmit = async() => {

   const col= {
    "SkillRating": {
        "Skill Rating": {
            "title": "Skill Rating",
            "facultyDataIndex": "rating",
            "key": "rating"
        },
        "No Of Years Of Skill Experience": {
            "title": "No Of Years Of Skill Experience",
            "facultyDataIndex": "years",
            "key": "years"
        }
    },
    "sid": {
        "Email": {
            "title": "Email",
            "facultyDataIndex": "studentEmail",
            "key": "studentEmail"
        },
        "Phone Number": {
            "title": "Phone Number",
            "facultyDataIndex": "studentPhone",
            "key": "studentPhone"
        }
    },
    "volunteering": {
        "Name Of the Organization": {
            "title": "Name Of the Organization",
            "facultyDataIndex": "organizationName",
            "key": "organizationName"
        },
        "Information About the Volunteering Role": {
            "title": "Information About the Volunteering Role",
            "facultyDataIndex": "description",
            "key": "description"
        },
        "Name Of the Event": {
            "title": "Name Of the Event",
            "facultyDataIndex": "eventName",
            "key": "eventName"
        },
        "National/International/State": {
            "title": "National/International/State",
            "facultyDataIndex": "level",
            "key": "level"
        }
    }
}

    let sendOptions = {};
    for (let i of Object.keys(Options)) {
      if (Options[i] != {}) { 
        sendOptions[i]={
          select:Options[i]
        }
      }
    }

    console.log(sendOptions);

    const finalCols = Options;
    for (let i of Object.keys(finalCols)) {
      if (Object.keys(finalCols[i]).length === 0) {
        delete finalCols[i];
      }
    }
    setOptions(finalCols);

    // console.log(columns);
    // let tableCols = [];
    // for (let i of Object.values(columns)) {
    //   console.log(i);
    //   tableCols = tableCols.concat(Object.values(i));
    // }
    // console.log(tableCols);
    // setColumns1(tableCols);

    await fetchDetails(sendOptions);
  };

  const handleSave = () => {
    const finalCols = Options;
    for (let i of Object.keys(finalCols)) {
      if (Object.keys(finalCols[i]).length === 0) {
        delete finalCols[i];
      }
    }
    setOptions(finalCols);
    localStorage.setItem("selectedType", selectedType);
    localStorage.setItem("studentFacultyDataOptions", JSON.stringify(finalCols));
    console.log(Options);
  };

  const handleLoad = () => {
    const st = localStorage.getItem("selectedType");
    setSelectedType(st);
    const op = JSON.parse(localStorage.getItem("studentFacultyDataOptions"));
    const selTables = op && Object.keys(op);

    const setop = {}
    op && selTables.map((table) => {
      const e = Object.keys(op[table]);
      const ind = schema.findIndex((item) => item.value == table);
      if (ind != -1) {
        
        const o = handleColChange(
          e,
          table,
          ind
        );
        setop[table] = o;
        }
    })

    setColumns(setop);

    setSelectedTables(Object.keys(op));
    setOptions(op);
  };

  useEffect(() => {
    console.log(Options);
    console.log(columns);
  }, [Options, columns]);
  
  
  useEffect(() => {
    console.log(FilteredSearchFacultyData);
  }, [FilteredSearchFacultyData]);

  useEffect(() => {
    const filteredResults = facultyData?.filter((result) => {
      const nameMatch = result.studentEmail
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      return nameMatch;
    });
    setFilteredSearchFacultyData(filteredResults);
  }, [searchTerm, facultyData]);

  if (loading) {
    return <Loader></Loader>;
  }



  return (
    <div className="facultyData-div">

      <div className='section-title'>FacultyData</div>

      <div>
        <Select
          style={{ width: 200 }}
          placeholder='Select type'
          onChange={(value) => setSelectedType(value)}>
          {/* Options for selecting type (student, faculty) */}
          <Select.Option value='student'>Student</Select.Option>
          <Select.Option value='faculty'>Faculty</Select.Option>
        </Select>
        {selectedType && selectedType === "student" && (
          <>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}>
              Check all
            </Checkbox>
            <Divider />
            <Checkbox.Group
              style={{ marginTop: "20px" }}
              options={schema}
              labelInValue={true}
              value={selectedTables}
              onChange={(values) => {
                console.log(values);
                setSelectedTables(values);
              }}></Checkbox.Group>
            {/* Display fields for selected tables */}
            <Collapse
              style={{ marginTop: "20px" }}
              items=
            {selectedTables.map((table, i) => {
              return {
                key: i,
                label: table,
                children: (
              // return (
                <div key={i}>
                  <h3>{table} Fields</h3>
                  <Checkbox.Group
                    style={{ marginTop: "20px" }}
                    options={
                      schema.find((item) => item.value == table)?.fields || []
                    }
                    value={
                      Options != {} && Options && Options[table]
                        ? Object.keys(Options[table])
                        : []
                    }
                    onChange={(e) =>
                      handleFieldChange(
                        e,
                        table,
                        schema.findIndex((item) => item.value == table)
                      )
                    }></Checkbox.Group>
                </div>
              // );
                ),
              };
            })}
            /> 

            {/* Form submission button */}
            <Button type='primary' onClick={handleSubmit}>
              Submit
            </Button>
            <Button type='primary' onClick={handleSave}>
              Save
            </Button>
            <Button type='primary' onClick={handleLoad}>
              Load
            </Button>
          </>
        )}
      </div>

      <Input
        className='request-search-input'
        type='text'
        placeholder='Search by student email'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

<MyTable  facultyDataSource={FilteredSearchFacultyData} columns={columns} />

    </div>
  );
};

export default FacultyData;
