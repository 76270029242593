import React, { useContext, useEffect, useState } from "react";
import Main from "../Layouts/Main";
import { UserContext } from "../../UserContext";
import { Button, Input, Modal, Table, message } from "antd";
import "./Requests.css";
import Loader from "../../Loader/Loader";

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { hodapiurl, token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [declinemodalVisible, setDeclineModalVisible] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [requestData, setrequestData] = useState({});
const [filteredSearchData, setFilteredSearchData] = useState([])
	
  const fetchPendingList = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${hodapiurl}/api/hod/student/pendinglist`, {
        method: "GET",

        headers: {
          // "Content-Type": "application/json",
          Authorization: "BEARER " + token,
        },
      });
      const data = await response.json();
      console.log(data);
      const filteredResults = data?.filter((result) => {
        const nameMatch = result.studentEmail
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        return nameMatch;
      });
      console.log(filteredResults);
      setRequests(filteredResults);
      setFilteredSearchData(filteredResults);
      setLoading(false);
    } catch (error) {
      message.error(`Error fetching Results`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingList();
  }, []);


  useEffect(() => {
    const filteredResults = requests?.filter((result) => {
      const nameMatch = result.studentEmail
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      return nameMatch;
	});
	  setFilteredSearchData(filteredResults);
  }, [searchTerm,requests]);
	

  if (loading) {
    return <Loader></Loader>;
  }

  const columns = [
    { title: "First Name", dataIndex: "firstName", key: "firstName" },
    { title: "Last Name", dataIndex: "lastName", key: "lastName" },
    { title: "Email", dataIndex: "studentEmail", key: "studentEmail" },
    {
      title: "Action",
      key: "action",
      render: (_, result) => (
        <Button
          onClick={() => {
            setrequestData(result);
            setViewModal(true);
          }}>
          View
        </Button>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, result) => (
        <Button
          onClick={() => {
            setRequestId(result.id);
            setModalVisible(true);
          }}>
          Approve
        </Button>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, result) => (
        <Button
          onClick={() => {
            setRequestId(result.id);
            setDeclineModalVisible(true);
          }}>
          Decline
        </Button>
      ),
    },
  ];

  const handleDecline = () => {
    setLoading(true);
    fetch(`${hodapiurl}/api/hod/student/decline/${requestId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "BEARER " + token,
      },
    })
      .then((response) => response.json())
      .then((updatedResult) => {
        fetchPendingList();
        setDeclineModalVisible(false);
        setLoading(false);
        message.success("Declined successfully");
      })
      .catch((error) => {
        setLoading(false);
        message.error("Error updating status:", error);
        setDeclineModalVisible(false);
      });
  };
  const handleApprove = () => {
    setLoading(true);
    console.log(requestId)
    fetch(`${hodapiurl}/api/hod/student/createar`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "BEARER " + token,
      },
      body: JSON.stringify({ studentRegisterId: requestId }),
    })
      .then((response) => response.json())
      .then((updatedResult) => {
        fetchPendingList();
        setModalVisible(false);
        setLoading(false);
        message.success("Accepted successfully");
      })
      .catch((error) => {
        setLoading(false);
        setModalVisible(false);
        message.error("Error updating status:", error);
      });
  };
  return (
    <Main>
      <div className='section-title'>Requests</div>
      <Input
        className='request-search-input'
        type='text'
        placeholder='Search by student email'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Table
        scroll={{
          y: "calc(100vh - 350px)",
        }}
        className='requests-data'
        dataSource={filteredSearchData}
        columns={columns}
      />

      <Modal
        title='Conformation'
        open={modalVisible}
        onOk={handleApprove}
        onCancel={() => {
          setModalVisible(false);
        }}
        okText='Approve'
        cancelText='Cancel'>
        <p>Are you sure Do you want to approve..?</p>
      </Modal>
		  <Modal
			  className="decline-student-modal"
        title='Conformation'
        open={declinemodalVisible}
        onOk={handleDecline}
        onCancel={() => {
          setDeclineModalVisible(false);
        }}
        okText='Decline'
        cancelText='Cancel'>
        <p>Are you sure Do you want to decline..?</p>
      </Modal>

      {requestData && <Modal
        title={
          <h2 style={{ fontFamily: "var(--font-family-primary)" }}>
            Student Details
          </h2>
        }
        open={viewModal}
        onCancel={() => setViewModal(false)}
        footer={
          <Button
            type='primary'
            onClick={() => {
              setViewModal(false);
            }}>
            Close
          </Button>
        }>
        <div className='stud-details'>
          {requestData &&
					  Object.keys(requestData).map((index, i) => {
				console.log(index)
              if (
                ![
                  "id",
                  "createdOn",
                  "updatedOn",
                  "department",
                  "category",
                  "community",
                  "admittedUnder",
                  "branchId",
                  "reservationCategoryId",
                  "communityId",
                  "admissionExamsId",
                ].includes(index)
              ) {
                return (
                  <li key={i}>
                    <div className='key'>{index}</div>
                    <div className='value'>{requestData[index]}</div>
                  </li>
                );
              } else if (index === "branch") {
                return (
                  <li key={i}>
                    <div className='key'>{index}</div>
                    <div className='value'>{requestData[index].name}</div>
                  </li>
                );
              } else if (index === "category") {
                return (
                  <li key={i}>
                    <div className='key'>{index}</div>
                    <div className='value'>
                      {requestData[index].categoryName}
                    </div>
                  </li>
                );
              } else if (index === "community") {
                return (
                  <li key={i}>
                    <div className='key'>{index}</div>
                    <div className='value'>
                      {requestData[index].communityName}
                    </div>
                  </li>
                );
              } else if (index === "admittedUnder") {
                return (
                  <li key={i}>
                    <div className='key'>{index}</div>
                    <div className='value'>{requestData[index].examName}</div>
                  </li>
                );
              }
            })}
          ;
        </div>
      </Modal>}
    </Main>
  );
};

export default Requests;
