import React from 'react'
import Main from '../Layouts/Main'
const CERTIFICATE_ADMIN_HOME = () => {
  return (
    <Main>
        <div>CERTIFICATE_ADMIN_HOME</div>
    </Main>
  )
}

export default CERTIFICATE_ADMIN_HOME;