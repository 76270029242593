import React, { useState } from "react";
import { Button, Divider, Form, Input } from "antd";
import { useAuth } from "../../useAuth";

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const Create = () => {
  const [data, setdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const { hodapiurl, token, success, error } = useAuth();

  const handleCreate = async (values) => {
    try {
      setLoading(true);
      console.log(values);

      const response = await fetch(`${hodapiurl}/api/hod/student/email/c`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "BEARER " + token,
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();

      const d = JSON.stringify(data);
      setdata(d);

      if (data.id) {
        success("Student Creation Successful");
      } else {
        error("Error Creating Student");
      }
    } catch (err) {
      error("Invalid Fields");
      //   console.error("Form validation failed", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        name='basic'
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={handleCreate}
        onFinishFailed={onFinishFailed}
        autoComplete='off'>
        <Form.Item label='Email' name='studentEmail'>
          <Input required />
        </Form.Item>

        <Form.Item label='Password' name='password'>
          <Input.Password required/>
        </Form.Item>
        <Form.Item label='CollegeId' name='collegeId' initialValue='JNTUGVCEV'>
          <Input required />
        </Form.Item>
        <Form.Item label='Roll Number' name='studentRoll'>
          <Input required/>
        </Form.Item>
        <Form.Item label='Phone Number' name='studentPhone'>
          <Input required/>
        </Form.Item>
        <Form.Item label='Year Of Admission' name='yearOfAdmission'>
          <Input required />
        </Form.Item>
        <Form.Item
          label='UniversityId'
          name='universityId'
          initialValue='JNTUGV'>
          <Input required/>
        </Form.Item>
        <Form.Item label='DepartmentId' name='departmentId' initialValue='CSE'>
          <Input required />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <h5>{data && data}</h5>
    </>
  );
};

export default Create;
