import React, { useEffect, useState } from "react";
import Main from "../Layouts/Main";
import { Button, Input, Modal, Table, message } from "antd";

import "./Data.css";
import { useAuth } from "../../useAuth";
import { Select, Checkbox, Form, DatePicker, Collapse, Divider } from "antd";
import { schema } from "./schemas/InformationSchema";
import MyTable from "./MyTable"
import { Tooltip } from "antd";
import {batchInfo,typeInfo} from "./schemas/batch"

import StudentInformation from "./DataComponents/StudentInformation"
import Education from "./DataComponents/Education";
import Experience from "./DataComponents/Experience";


const StudentData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { hodapiurl, token,success,error } = useAuth();

  const [selectedType, setSelectedType] = useState("");
  const [selectedBatch, setSelectedBatch] = useState("");


  return (
    <div className='data-div'>
      <div>
        <Divider />
        <Divider />

        <strong>Select Student Information</strong>
        <Select
          style={{ width: 200, margin: "1rem" }}
          placeholder='Select type'
          value={selectedType}
          onChange={(value) => setSelectedType(value)}>
          {/* Options for selecting type (student, faculty) */}
          {typeInfo &&
            Object.keys(typeInfo).map((item) => {
              return (
                <Select.Option value={item}>{typeInfo[item]}</Select.Option>
              );
            })}
        </Select>
        <Divider />

        <strong>Select Batch</strong>
        <Select
          style={{ width: 200, margin: "1rem" }}
          placeholder='Select type'
          value={selectedBatch}
          onChange={(value) => setSelectedBatch(value)}>
          {/* Options for selecting type (student, faculty) */}
          {batchInfo &&
            Object.keys(batchInfo).map((item, i) => {
              return (
                <Select.Option key={i} value={item}>
                  {batchInfo[item]}
                </Select.Option>
              );
            })}
        </Select>
        <Divider />

        {selectedType &&
          selectedBatch &&
          selectedType === "studentRegister" && (
            <StudentInformation
              title={`${typeInfo[selectedType]} - ${batchInfo[selectedBatch]}`}
              batch={selectedBatch}
            />
          )}
        {selectedType && selectedBatch && selectedType === "education" && (
          <Education
            title={`${typeInfo[selectedType]} - ${batchInfo[selectedBatch]}`}
            batch={selectedBatch}
          />
        )}
        {selectedType && selectedBatch && selectedType === "experience" && (
          <Experience
            title={`${typeInfo[selectedType]} - ${batchInfo[selectedBatch]}`}
            batch={selectedBatch}
          />
        )}
      </div>
    </div>
  );
};

export default StudentData;
