import React from 'react'
import {Divider} from 'antd'
import Create from './Create';

const StudentRegister = () => {
  return (
    <div>
      <h2>
        Ask Student To Register at{" "}
        <a
          href='http://student.jntugv.edu.in/register'
          target='_blank'
          rel='noopener noreferrer'>
          student.jntugv.edu.in/register
        </a>{" "}
        and then you can Approve in Requests Tab
      </h2>
      <Divider />
      <h3 style={{color:"red"}}>
      Student with valid rollnumber can be registered here
      </h3>
      <Divider />
      <Create />
    </div>
  );
}

export default StudentRegister
