import React from 'react'
import Main from '../Layouts/Main'
const HOD_HELPER_HOME = () => {
  return (
    <Main>
        <div>HOD_HELPER_HOME</div>
    </Main>
  )
}

export default HOD_HELPER_HOME;